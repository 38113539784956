import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./App.css";
import appInfo from "./appInfo";

const DashContent = ({ setRight, setActiveDocument, highlightColor }) => {
  const [userData, setUserData] = useState();
  const token = Cookies.get("token");
  const [allInvoice, setAllInvoice] = useState([]);
  const [allQuote, setAllQuote] = useState([]);
  const getInvoice = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getInvoiceAll",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      const gottenInvoice = await getInvoice();
      if (gottenInvoice) {
        const updatedInvoices = gottenInvoice.invoice.map((invoice) => ({
          ...invoice,
          less_cv: parseFloat(invoice.less_cv, 10),
          discount: parseFloat(invoice.discount, 10),
          status: parseFloat(invoice.status, 10),
        }));
        setAllInvoice(updatedInvoices);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchInvoice();
  }, []);

  const getQuote = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getQuoteAll",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchQuote = async () => {
      const gottenQuote = await getQuote();
      if (gottenQuote) {
        const updatedQuotes = gottenQuote.quotes.map((quote) => ({
          ...quote,
          less_cv: parseFloat(quote.less_cv, 10),
          discount: parseFloat(quote.discount, 10),
          status: parseFloat(quote.status, 10),
        }));
        setAllQuote(updatedQuotes);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchQuote();
  }, []);

  // Function to filter data by date range
  const filterDataByDateRange = (data, dateRange) => {
    return data.filter((item) => {
      const itemDate = new Date(item.date); // Convert the item's date string to a Date object
      return itemDate >= dateRange.startDate && itemDate <= dateRange.endDate;
    });
  };

  const [dateRange, setDateRange] = useState({
    startDate: new Date("2023-01-01"),
    endDate: new Date("2023-12-31"),
    key: "selection",
  });

  const filteredQuotes = filterDataByDateRange(allQuote, dateRange);
  const filteredInvoices = filterDataByDateRange(allInvoice, dateRange);

  const [dateRangeIsOpen, setDateRangeIsOpen] = useState(false);
  const getReceiptValue = () => {
    let letReceipts = 0;

    for (const invoice of filteredInvoices) {
      letReceipts += parseFloat(invoice.record_payment);
    }
    return letReceipts.toFixed(2);
  };

  const getOutstandingValue = () => {
    let letOutstanding = 0;

    for (const invoice of filteredInvoices) {
      letOutstanding +=
        parseFloat(
          invoice.product.reduce(
            (acc, item) =>
              acc + parseFloat(item.quantity) * parseFloat(item.UnitPrice),
            0
          ) -
            parseFloat(invoice.discount) -
            parseFloat(invoice.less_cv)
        ) *
          1.08 -
        parseFloat(invoice.record_payment);
    }
    return parseFloat(letOutstanding.toFixed(2));
  };
  const getTotalRevenue = () => {
    let letOutstanding = 0;

    for (const invoice of filteredInvoices) {
      letOutstanding +=
        parseFloat(
          invoice.product.reduce(
            (acc, item) =>
              acc + parseFloat(item.quantity) * parseFloat(item.UnitPrice),
            0
          ) -
            parseFloat(invoice.discount) -
            parseFloat(invoice.less_cv)
        ) * 1.08;
    }
    return parseFloat(letOutstanding.toFixed(2));
  };

  const getTotalQuote = () => {
    let letOutstanding = 0;

    for (const invoice of filteredQuotes) {
      letOutstanding +=
        parseFloat(
          invoice.product.reduce(
            (acc, item) =>
              acc + parseFloat(item.quantity) * parseFloat(item.UnitPrice),
            0
          ) -
            parseFloat(invoice.discount) -
            parseFloat(invoice.less_cv)
        ) * 1.08;
    }
    return parseFloat(letOutstanding.toFixed(2));
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getuserdata",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setUserData(data.user_data);
      console.log(data.user_data);
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    // Add event listener to update the screenWidth state when the window is resized
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          background: "white",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          borderBottom: "1px solid lightgrey",
        }}
      >
        <div>
          <div style={{ fontSize: "35px", fontWeight: "700" }}>Dashboard</div>
          <div style={{ fontSize: "14px", fontWeight: "400" }}>
            Welcome back, {userData ? userData.name : "user"}!
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              background: "grey",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <AccountCircleIcon style={{ color: "white", fontSize: "45px" }} />
          </div>
        </div>
      </div>
      <div
        style={{
          height: "calc(100vh - 70px)",
          width: "calc(100% - 40px)",
          background: "rgb(247, 246, 245)",
          padding: "0px 20px",
        }}
      >
        <div
          style={{
            width: "100%",
            background: "#282828",
            height: windowHeight > screenWidth ? "340px" : "150px",
            marginTop: "20px",
            borderRadius: "20px",
            display: "flex",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "99%",
              padding: ".5%",
              gap: ".4%",
              flexWrap: "wrap",
            }}
          >
            <div
              className="dash_main"
              style={{
                position: "relative",
                width:
                  windowHeight > screenWidth
                    ? "calc(50% - 50px)"
                    : "calc(25% - 50px)",
                background: "#282828",
                height:
                  windowHeight > screenWidth
                    ? "calc(50% - 45px)"
                    : "calc(100% - 40px)",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid white",
              }}
              onClick={() => {
                setRight("Report");
              }}
            >
              <div
                className="blink"
                style={{
                  position: "absolute",
                  top: 15,
                  right: 15,
                  background: highlightColor,
                  width: "8px",
                  height: "8px",
                  borderRadius: "50%",
                  boxShadow: "0px 0px 10px #eba1a2",
                }}
              ></div>
              <div
                className="dash_main_text"
                style={{
                  width: "90%",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: highlightColor,
                }}
              >
                Total Revenue
              </div>
              <div
                className="dash_main_text"
                style={{
                  width: "90%",
                  fontSize: "30px",
                  fontWeight: "700",
                  color: highlightColor,
                }}
              >
                SGD {getTotalRevenue()}
              </div>
            </div>
            <div
              className="dash_main"
              style={{
                position: "relative",
                width:
                  windowHeight > screenWidth
                    ? "calc(50% - 50px)"
                    : "calc(25% - 50px)",
                background: "#282828",
                height:
                  windowHeight > screenWidth
                    ? "calc(50% - 45px)"
                    : "calc(100% - 40px)",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid white",
              }}
              onClick={() => {
                setRight("Quote");
              }}
            >
              <div
                className="dash_main_text"
                style={{
                  width: "90%",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#f1f1f1",
                }}
              >
                Outstanding Quotes
              </div>
              <div
                className="dash_main_text"
                style={{
                  width: "90%",
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#f1f1f1",
                }}
              >
                SGD {getTotalQuote()}
              </div>
            </div>
            <div
              className="dash_main"
              style={{
                position: "relative",
                width:
                  windowHeight > screenWidth
                    ? "calc(50% - 50px)"
                    : "calc(25% - 50px)",
                background: "#282828",
                height:
                  windowHeight > screenWidth
                    ? "calc(50% - 45px)"
                    : "calc(100% - 40px)",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid white",
              }}
              onClick={() => {
                setRight("Invoice");
              }}
            >
              <div
                className="dash_main_text"
                style={{
                  width: "90%",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#f1f1f1",
                }}
              >
                Outstanding Receivables
              </div>
              <div
                className="dash_main_text"
                style={{
                  width: "90%",
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#f1f1f1",
                }}
              >
                SGD {getOutstandingValue()}
              </div>
            </div>
            <div
              className="dash_main"
              style={{
                position: "relative",
                width:
                  windowHeight > screenWidth
                    ? "calc(50% - 50px)"
                    : "calc(25% - 50px)",
                background: "#282828",
                height:
                  windowHeight > screenWidth
                    ? "calc(50% - 45px)"
                    : "calc(100% - 40px)",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid white",
              }}
              onClick={() => {
                setRight("Invoice");
              }}
            >
              <div
                className="dash_main_text"
                style={{
                  width: "90%",
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#f1f1f1",
                }}
              >
                Total Receipt
              </div>
              <div
                className="dash_main_text"
                style={{
                  width: "90%",
                  fontSize: "30px",
                  fontWeight: "700",
                  color: "#f1f1f1",
                }}
              >
                SGD {getReceiptValue()}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <div
            style={{
              width: "calc(49.6% - 30px)",
              border: "1px solid lightgrey",
              borderRadius: "20px",
              height: "600px",
              padding: "15px",
              background: "white",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid lightgrey",
                fontSize: "30px",
                fontWeight: "600",
                color: highlightColor,
                padding: "5px 10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div>Quotes</div>
              <div
                style={{
                  background: highlightColor,
                  color: "white",
                  fontSize: "14px",
                  padding: "8px 20px",
                  borderRadius: "12px",
                  cursor: "pointer",
                }}
                onClick={() => setRight("Quote")}
              >
                Create Quote
              </div>
            </div>
            <div style={{ overflowY: "scroll", height: "550px" }}>
              {allQuote.map((quote) => (
                <div
                  onClick={() => {
                    setRight("Quote");
                    setActiveDocument(quote.quote_number);
                  }}
                  className="invoice_item"
                  style={{
                    padding: "10px",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    QUOTE-{quote.quote_number}{" "}
                    <div
                      style={{
                        color: "grey",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      ({quote.date})
                    </div>
                  </div>
                  <div>
                    ${" "}
                    {(
                      (quote.product.reduce(
                        (acc, item) =>
                          acc +
                          parseFloat(item.quantity) *
                            parseFloat(item.UnitPrice),
                        0
                      ) -
                        parseFloat(quote.discount) -
                        parseFloat(quote.less_cv)) *
                      1.08
                    ).toFixed(2)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              width: "calc(49.6% - 30px)",
              border: "1px solid lightgrey",
              borderRadius: "20px",
              height: "600px",
              padding: "15px",
              background: "white",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid lightgrey",
                fontSize: "30px",
                fontWeight: "600",
                color: highlightColor,
                padding: "5px 10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div>Invoices</div>
            </div>
            <div style={{ overflowY: "scroll", height: "550px" }}>
              {allInvoice.map((invoice) => (
                <div
                  onClick={() => {
                    setRight("Invoice");
                    setActiveDocument(invoice.invoice_number);
                  }}
                  className="invoice_item"
                  style={{
                    padding: "10px",
                    borderRadius: "12px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {" "}
                    INVOICE-{invoice.invoice_number}{" "}
                    <div
                      style={{
                        color: "grey",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      ({invoice.date})
                    </div>
                  </div>
                  <div>
                    ${" "}
                    {(
                      (invoice.product.reduce(
                        (acc, item) =>
                          acc +
                          parseFloat(item.quantity) *
                            parseFloat(item.UnitPrice),
                        0
                      ) -
                        parseFloat(invoice.discount) -
                        parseFloat(invoice.less_cv)) *
                      1.08
                    ).toFixed(2)}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashContent;
