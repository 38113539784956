import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import icon from "./img/gc_logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import RedeemIcon from "@mui/icons-material/Redeem";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import ReceiptIcon from "@mui/icons-material/Receipt";
import InsightsIcon from "@mui/icons-material/Insights";
import DashContent from "./DashContent";
import Invoice from "./Invoice";
import Quote from "./Quote";
import Product from "./Product";
import Report from "./Report";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import Cart from "./Cart";
import UserAccess from "./UserAccess";
import PeopleIcon from "@mui/icons-material/People";
import Customer from "./Customer";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Receipt from "./Receipt";

import { fallDown as Menu } from "react-burger-menu";
import Hamburger from "hamburger-react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import appInfo from "./appInfo";
import { SliderPicker } from "react-color";

const Dashboard = () => {
  const navigate = useNavigate();
  const token = Cookies.get("token");

  const [rightColumn, setRightColumn] = useState(
    localStorage.getItem("rightColumn") || "Dashboard"
  );

  useEffect(() => {
    // Store the selectedLink value in localStorage whenever it changes
    localStorage.setItem("rightColumn", rightColumn);
  }, [rightColumn]);

  const [activeCart, setActiveCart] = useState([]);
  const [activeClient, setActiveClient] = useState(null);
  const [logoutModal, setLogoutModal] = useState(false);
  const [userData, setUserData] = useState();
  const [quoteToInvoice, setQuoteToInvoice] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const [highlightColor, setHighlightColor] = useState(
    Cookies.get("highlightColor") || highlightColor
  );

  useEffect(() => {
    Cookies.set("highlightColor", highlightColor);
  }, [highlightColor]);

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getuserdata",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setUserData(data.user_data);
      console.log(data.user_data);
      setUserId(data.user_data.id);
      setUserRole(data.user_data.role);
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };
  useEffect(() => {
    if (!token) {
      Cookies.remove("token");
      navigate("/"); // programmatically navigate to /home
    }
    fetchUserData();
  }, []);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    // Add event listener to update the screenWidth state when the window is resized
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  function handleToggleMenu() {
    setIsOpen(!isOpen);
  }

  const [activeDocument, setActiveDocument] = useState(null);

  return (
    <div
      id="outer-container"
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: screenWidth > windowHeight ? "row" : "column",
        height: `${windowHeight}px`,
        overflow: "hidden",
      }}
    >
      <Modal
        ariaHideApp={false}
        isOpen={logoutModal}
        onRequestClose={() => {
          setLogoutModal(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px",
            height: "250px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflowX: "hidden",
            overflowY: "hidden",
            padding: "none",
          },
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "calc(100% - 40px)",
              justifyContent: "flex-end",
              padding: "20px",
            }}
          >
            <div
              className="close_icon"
              style={{
                opacity: "1",
                transition: "all 0.3s ease-in-out",
                border: "1px solid lightgrey",
                borderRadius: "50%",
                height: "40px",
                width: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setLogoutModal(false);
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "calc(100% - 40px)",
              padding: "0px 20px",
              height: "75%",
              marginTop: "-50px",
            }}
          >
            <div style={{ fontWeight: "600", fontSize: "20px" }}>
              Confirm Logout
            </div>
            <div
              style={{
                fontWeight: "600",
                fontSize: "20px",
                color: "white",
                background: highlightColor,
                padding: "10px 40px",
                borderRadius: "40px",
                marginTop: "10px",
                cursor: "pointer",
              }}
              onClick={() => {
                Cookies.remove("token");
                navigate("/");
              }}
            >
              Logout
            </div>
          </div>
        </div>
      </Modal>
      {screenWidth > windowHeight ? (
        <div
          style={{
            width: "13%",
            background: "#282828",
            padding: "1%",
            height: `${windowHeight * 0.97}px`,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <img
              src={icon}
              style={{ width: "100%", cursor: "pointer" }}
              onClick={() => {
                setRightColumn("Dashboard");
                setIsOpen(false);
              }}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginTop: "20px",
              }}
            >
              <div
                className="menu_item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: "10px 12%",
                  background: rightColumn === "Dashboard" ? "grey" : "none",
                  width: "70%",
                  borderRadius: "10px",
                }}
                onClick={() => setRightColumn("Dashboard")}
              >
                <DashboardIcon
                  className="mui_menu_icon"
                  style={{ marginRight: "10px", fontSize: "15px" }}
                />
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  Dashboard
                </div>
              </div>

              {userRole !== "Sales" ? (
                <div
                  className="menu_item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    padding: "10px 12%",
                    background: rightColumn === "Access" ? "grey" : "none",
                    width: "70%",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setRightColumn("UserAccess");
                  }}
                >
                  <PeopleOutlineIcon
                    className="mui_menu_icon"
                    style={{ marginRight: "10px", fontSize: "15px" }}
                  />
                  <div style={{ fontSize: "14px", fontWeight: "500" }}>
                    User Access
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div
                className="menu_item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: "10px 12%",
                  background: rightColumn === "Customer" ? "grey" : "none",
                  width: "70%",
                  borderRadius: "10px",
                }}
                onClick={() => {
                  console.log(userId);
                  setRightColumn("Customer");
                }}
              >
                <PeopleIcon
                  className="mui_menu_icon"
                  style={{ marginRight: "10px", fontSize: "15px" }}
                />
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  Customer
                </div>
              </div>
              <div
                className="menu_item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: "10px 12%",
                  background: rightColumn === "Product" ? "grey" : "none",
                  width: "70%",
                  borderRadius: "10px",
                }}
                onClick={() => setRightColumn("Product")}
              >
                <RedeemIcon
                  className="mui_menu_icon"
                  style={{ marginRight: "10px", fontSize: "15px" }}
                />
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  Products
                </div>
              </div>
              <div
                className="menu_item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: "10px 12%",
                  background: rightColumn === "Cart" ? "grey" : "none",
                  width: "70%",
                  borderRadius: "10px",
                }}
                onClick={() => setRightColumn("Cart")}
              >
                <ShoppingCartCheckoutIcon
                  className="mui_menu_icon"
                  style={{ marginRight: "10px", fontSize: "15px" }}
                />
                <div style={{ fontSize: "14px", fontWeight: "500" }}>Cart</div>
              </div>
              <div
                className="menu_item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: "10px 12%",
                  background: rightColumn === "Quote" ? "grey" : "none",
                  width: "70%",
                  borderRadius: "10px",
                }}
                onClick={() => setRightColumn("Quote")}
              >
                <ContentPasteGoIcon
                  className="mui_menu_icon"
                  style={{ marginRight: "10px", fontSize: "15px" }}
                />
                <div style={{ fontSize: "14px", fontWeight: "500" }}>Quote</div>
              </div>
              <div
                className="menu_item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: "10px 12%",
                  background: rightColumn === "Invoice" ? "grey" : "none",
                  width: "70%",
                  borderRadius: "10px",
                }}
                onClick={() => setRightColumn("Invoice")}
              >
                <RequestPageIcon
                  className="mui_menu_icon"
                  style={{ marginRight: "10px", fontSize: "15px" }}
                />
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  Invoice
                </div>
              </div>
              <div
                className="menu_item"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  padding: "10px 12%",
                  background: rightColumn === "Receipt" ? "grey" : "none",
                  width: "70%",
                  borderRadius: "10px",
                }}
                onClick={() => setRightColumn("Receipt")}
              >
                <ReceiptIcon
                  className="mui_menu_icon"
                  style={{ marginRight: "10px", fontSize: "15px" }}
                />
                <div style={{ fontSize: "14px", fontWeight: "500" }}>
                  Sales Receipts
                </div>
              </div>
              {userRole !== "Sales" ? (
                <div
                  className="menu_item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 12%",
                    background: rightColumn === "Report" ? "grey" : "none",
                    width: "70%",
                    borderRadius: "10px",
                  }}
                  onClick={() => setRightColumn("Report")}
                >
                  <InsightsIcon
                    className="mui_menu_icon"
                    style={{ marginRight: "10px", fontSize: "15px" }}
                  />
                  <div style={{ fontSize: "14px", fontWeight: "500" }}>
                    Reports
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div>
            <SliderPicker
              color={highlightColor}
              onChange={({ hex }) => setHighlightColor(hex)}
            />
            <div
              style={{
                color: "white",
                display: "flex",
                alignItems: "center",
                height: "60px",
                fontSize: "23px",
              }}
            >
              <StarBorderIcon
                style={{
                  color: highlightColor,
                  fontSize: "30px",
                  marginRight: "8px",
                }}
              />
              {userData
                ? userData.role.charAt(0).toUpperCase() +
                  userData.role.slice(1).toLowerCase()
                : ""}
            </div>
            <div
              style={{
                background: highlightColor,
                width: "70%",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                padding: "15px 12%",
                cursor: "pointer",
                color: "white",
                fontWeight: "600",
              }}
              onClick={() => {
                setLogoutModal(true);
              }}
            >
              <PowerSettingsNewIcon
                style={{ marginRight: "10px", fontSize: "20px" }}
              />
              Logout
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              height: "5vh",
              background: "#282828",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              zIndex: 5000,
              position: "relative",
            }}
          >
            <div>
              <img
                src={icon}
                style={{ height: "5vh", cursor: "pointer" }}
                onClick={() => {
                  setRightColumn("Dashboard");
                  setIsOpen(false);
                }}
              />
            </div>
            <div
              style={{
                color: "white",
                display: "flex",
                alignItems: "center",
                height: "60px",
                fontSize: "23px",
                marginRight: "calc(5vh + 20px)",
              }}
            >
              <StarBorderIcon
                style={{
                  color: highlightColor,
                  fontSize: "30px",
                  marginRight: "8px",
                }}
              />
              {userData
                ? userData.role.charAt(0).toUpperCase() +
                  userData.role.slice(1).toLowerCase()
                : ""}
            </div>
          </div>
          <Menu
            right
            width={"100%"}
            isOpen={isOpen}
            customCrossIcon={false}
            customBurgerIcon={
              <Hamburger
                toggled={isOpen}
                toggle={handleToggleMenu}
                size={24}
                color="#ffff"
              />
            }
          >
            <div>
              <div
                onClick={() => {
                  setRightColumn("Dashboard");
                  setIsOpen(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "13px 0px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    borderRadius: "10px",
                  }}
                >
                  <DashboardIcon
                    className="mui_menu_icon"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "grey",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "grey",
                    }}
                  >
                    Dashboard
                  </div>
                </div>
                <NavigateNextIcon />
              </div>

              {userRole !== "Sales" ? (
                <>
                  <hr
                    style={{
                      width: "100%",
                      height: "0.05rem",
                      background: "lightgrey",
                      outline: "none",
                      border: "none",
                    }}
                  />

                  <div
                    onClick={() => {
                      setRightColumn("UserAccess");
                      setIsOpen(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "13px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: "none",
                        borderRadius: "10px",
                      }}
                    >
                      <PeopleOutlineIcon
                        className="mui_menu_icon"
                        style={{
                          marginRight: "10px",
                          fontSize: "18px",
                          color: "grey",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "grey",
                        }}
                      >
                        User Access
                      </div>
                    </div>
                    <NavigateNextIcon />
                  </div>
                </>
              ) : (
                <></>
              )}

              <hr
                style={{
                  width: "100%",
                  height: "0.05rem",
                  background: "lightgrey",
                  outline: "none",
                  border: "none",
                }}
              />

              <div
                onClick={() => {
                  setRightColumn("Customer");
                  setIsOpen(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "13px 0px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    borderRadius: "10px",
                  }}
                >
                  <PeopleIcon
                    className="mui_menu_icon"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "grey",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "grey",
                    }}
                  >
                    Customer
                  </div>
                </div>
                <NavigateNextIcon />
              </div>

              <hr
                style={{
                  width: "100%",
                  height: "0.05rem",
                  background: "lightgrey",
                  outline: "none",
                  border: "none",
                }}
              />

              <div
                onClick={() => {
                  setRightColumn("Product");
                  setIsOpen(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "13px 0px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    borderRadius: "10px",
                  }}
                >
                  <RedeemIcon
                    className="mui_menu_icon"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "grey",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "grey",
                    }}
                  >
                    Products
                  </div>
                </div>
                <NavigateNextIcon />
              </div>

              <hr
                style={{
                  width: "100%",
                  height: "0.05rem",
                  background: "lightgrey",
                  outline: "none",
                  border: "none",
                }}
              />

              <div
                onClick={() => {
                  setRightColumn("Cart");
                  setIsOpen(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "13px 0px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    borderRadius: "10px",
                  }}
                >
                  <ShoppingCartCheckoutIcon
                    className="mui_menu_icon"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "grey",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "grey",
                    }}
                  >
                    Cart
                  </div>
                </div>
                <NavigateNextIcon />
              </div>

              <hr
                style={{
                  width: "100%",
                  height: "0.05rem",
                  background: "lightgrey",
                  outline: "none",
                  border: "none",
                }}
              />

              <div
                onClick={() => {
                  setRightColumn("Quote");
                  setIsOpen(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "13px 0px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    borderRadius: "10px",
                  }}
                >
                  <ContentPasteGoIcon
                    className="mui_menu_icon"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "grey",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "grey",
                    }}
                  >
                    Quote
                  </div>
                </div>
                <NavigateNextIcon />
              </div>

              <hr
                style={{
                  width: "100%",
                  height: "0.05rem",
                  background: "lightgrey",
                  outline: "none",
                  border: "none",
                }}
              />

              <div
                onClick={() => {
                  setRightColumn("Invoice");
                  setIsOpen(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "13px 0px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    borderRadius: "10px",
                  }}
                >
                  <RequestPageIcon
                    className="mui_menu_icon"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "grey",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "grey",
                    }}
                  >
                    Invoice
                  </div>
                </div>
                <NavigateNextIcon />
              </div>

              <hr
                style={{
                  width: "100%",
                  height: "0.05rem",
                  background: "lightgrey",
                  outline: "none",
                  border: "none",
                }}
              />

              <div
                onClick={() => {
                  setRightColumn("Receipt");
                  setIsOpen(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "13px 0px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background: "none",
                    borderRadius: "10px",
                  }}
                >
                  <ReceiptIcon
                    className="mui_menu_icon"
                    style={{
                      marginRight: "10px",
                      fontSize: "18px",
                      color: "grey",
                    }}
                  />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      color: "grey",
                    }}
                  >
                    Sales Receipts
                  </div>
                </div>
                <NavigateNextIcon />
              </div>

              {userRole !== "Sales" ? (
                <>
                  <hr
                    style={{
                      width: "100%",
                      height: "0.05rem",
                      background: "lightgrey",
                      outline: "none",
                      border: "none",
                    }}
                  />

                  <div
                    onClick={() => {
                      setRightColumn("Report");
                      setIsOpen(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "13px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        background: "none",
                        borderRadius: "10px",
                      }}
                    >
                      <InsightsIcon
                        className="mui_menu_icon"
                        style={{
                          marginRight: "10px",
                          fontSize: "18px",
                          color: "grey",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "18px",
                          fontWeight: "500",
                          color: "grey",
                        }}
                      >
                        Reports
                      </div>
                    </div>
                    <NavigateNextIcon />
                  </div>
                </>
              ) : null}

              <div
                style={{
                  background: highlightColor,
                  width: "90%",
                  textAlign: "center",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "center",
                  color: "white",
                  fontWeight: "600",
                  padding: "15px 20px",
                  marginTop: "35px",
                }}
                onClick={() => {
                  setIsOpen(false);
                  setLogoutModal(true);
                }}
              >
                <PowerSettingsNewIcon
                  style={{ marginRight: "10px", fontSize: "20px" }}
                />
                Logout
              </div>
            </div>
          </Menu>
        </div>
      )}
      {screenWidth > windowHeight ? (
        <div style={{ width: "87%", padding: "0px 0px" }}>
          {rightColumn === "Dashboard" ? (
            <DashContent
              setRight={(item) => setRightColumn(item)}
              setActiveDocument={(activeDocument) =>
                setActiveDocument(activeDocument)
              }
              highlightColor={highlightColor}
            />
          ) : rightColumn === "Invoice" ? (
            <Invoice
              convertToInvoice={quoteToInvoice}
              onLoadClearActive={() => {
                setQuoteToInvoice(null);
              }}
              highlightColor={highlightColor}
              changeToReceipt={() => setRightColumn("Receipt")}
              userId={userId}
              activeDocument={activeDocument}
              clearActiveDocument={() => setActiveDocument(null)}
            />
          ) : rightColumn === "Quote" ? (
            <Quote
              activeClient={activeClient}
              activeCart={activeCart}
              onLoadClearActive={() => {
                setActiveCart([]);
                setActiveClient(null);
              }}
              highlightColor={highlightColor}
              convertInvoice={(quoteToInvoice) => {
                setQuoteToInvoice(quoteToInvoice);
                console.log(quoteToInvoice);
              }}
              onConvertInvoice={() => setRightColumn("Invoice")}
              userId={userId}
              activeDocument={activeDocument}
              clearActiveDocument={() => setActiveDocument(null)}
            />
          ) : rightColumn === "Product" ? (
            <Product highlightColor={highlightColor} />
          ) : rightColumn === "Cart" ? (
            <Cart
              onCreateQuote={(activeClientCart, activeClientName) => {
                setActiveCart(activeClientCart);
                setActiveClient(activeClientName);
                setRightColumn("Quote");
              }}
              highlightColor={highlightColor}
            />
          ) : rightColumn === "UserAccess" ? (
            <UserAccess highlightColor={highlightColor} />
          ) : rightColumn === "Report" ? (
            <Report highlightColor={highlightColor} />
          ) : rightColumn === "Customer" ? (
            <Customer
              userId={userId}
              setRight={(item) => setRightColumn(item)}
              setActiveDocument={(activeDocument) =>
                setActiveDocument(activeDocument)
              }
              highlightColor={highlightColor}
            />
          ) : (
            <Receipt
              convertToInvoice={quoteToInvoice}
              onLoadClearActive={() => {
                setQuoteToInvoice(null);
              }}
              highlightColor={highlightColor}
              userId={userId}
            />
          )}
        </div>
      ) : (
        <div style={{ width: "100%", padding: "0px 0px" }}>
          {rightColumn === "Dashboard" ? (
            <DashContent
              setRight={(item) => setRightColumn(item)}
              setActiveDocument={(activeDocument) =>
                setActiveDocument(activeDocument)
              }
              highlightColor={highlightColor}
            />
          ) : rightColumn === "Invoice" ? (
            <Invoice
              convertToInvoice={quoteToInvoice}
              onLoadClearActive={() => {
                setQuoteToInvoice(null);
              }}
              highlightColor={highlightColor}
              changeToReceipt={() => setRightColumn("Receipt")}
              userId={userId}
              activeDocument={activeDocument}
              clearActiveDocument={() => setActiveDocument(null)}
            />
          ) : rightColumn === "Quote" ? (
            <Quote
              activeClient={activeClient}
              activeCart={activeCart}
              onLoadClearActive={() => {
                setActiveCart([]);
                setActiveClient(null);
              }}
              highlightColor={highlightColor}
              convertInvoice={(quoteToInvoice) => {
                setQuoteToInvoice(quoteToInvoice);
                console.log(quoteToInvoice);
              }}
              onConvertInvoice={() => setRightColumn("Invoice")}
              userId={userId}
              activeDocument={activeDocument}
              clearActiveDocument={() => setActiveDocument(null)}
            />
          ) : rightColumn === "Product" ? (
            <Product highlightColor={highlightColor} />
          ) : rightColumn === "Cart" ? (
            <Cart
              highlightColor={highlightColor}
              onCreateQuote={(activeClientCart, activeClientName) => {
                setActiveCart(activeClientCart);
                setActiveClient(activeClientName);
                setRightColumn("Quote");
              }}
            />
          ) : rightColumn === "UserAccess" ? (
            <UserAccess highlightColor={highlightColor} />
          ) : rightColumn === "Report" ? (
            <Report highlightColor={highlightColor} />
          ) : rightColumn === "Customer" ? (
            <Customer
              userId={userId}
              highlightColor={highlightColor}
              setRight={(item) => setRightColumn(item)}
              setActiveDocument={(activeDocument) =>
                setActiveDocument(activeDocument)
              }
            />
          ) : (
            <Receipt
              convertToInvoice={quoteToInvoice}
              onLoadClearActive={() => {
                setQuoteToInvoice(null);
              }}
              highlightColor={highlightColor}
              userId={userId}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
