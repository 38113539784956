import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import AddIcon from "@mui/icons-material/Add";
import logo from "./img/gc_logo.png";
import qrCode from "./img/qr_code.jpeg";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PaidIcon from "@mui/icons-material/Paid";
import ReactPDF from "@react-pdf/renderer";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import Cookies from "js-cookie";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import axios from "axios";
import Modal from "react-modal";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import fallbackImage from "./img/7fd2cdd1-31a7-4810-bf03-137aaea45f12.jpeg";
import boldFont from "./img/Poppins-SemiBold.ttf";
import appInfo from "./appInfo";

Font.register({ family: "BoldFont", src: boldFont, fontWeight: "bold" });
const Quote = ({
  activeClient,
  activeCart,
  onLoadClearActive,
  convertInvoice,
  onConvertInvoice,
  userId,
  activeDocument,
  clearActiveDocument,
  highlightColor
}) => {
  const navigate = useNavigate();
  const [quoteDataForCondition, setquoteDataForCondition] = useState();
  const [allQuote, setAllQuote] = useState([]);
  const [showClientList, setShowClientList] = useState(false);
  const [showProductList, setShowProductList] = useState(false);
  const [userData, setUserData] = useState();
  const [voucherIsOpen, setVoucherIsOpen] = useState(false);
  const [discountIsOpen, setDiscountIsOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [selectedUser, setSelectedUser] = useState(""); // Initialize with an empty string or default user if needed

  const [selectedFilters, setSelectedFilters] = useState([]);

  function getCurrentYear() {
    const now = new Date();
    return now.getFullYear();
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
      console.log(window.innerWidth);
      console.log(window.innerHeight);
    };
    handleResize();
    // Add event listener to update the screenWidth state when the window is resized
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getuserdata",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setUserData(data.user_data);
      console.log(data.user_data);
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  const [invoice, setInvoice] = useState([]);

  const getInvoice = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getInvoice",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setInvoice(data.invoice);
      console.log(data.invoice);
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  const invoiceExist = (quoteNumber) => {
    const invoiceIndex = invoice.findIndex(
      (inv) => inv.quote_number === quoteNumber
    );
    if (invoiceIndex !== -1) {
      console.log(invoiceIndex);
      return true;
    } else {
      // console.log(false);
      return false;
    }
  };

  useEffect(() => {
    if (!token) {
      Cookies.remove("token");
      navigate("/"); // programmatically navigate to /home
    }
    fetchUserData();
    getInvoice();
  }, []);

  const calculateTotalValueGiven = (quotedata) => {
    let totalValue = 0;

    for (const product of quotedata.product) {
      const quantity = product.quantity;
      const unitPrice = parseFloat(product.UnitPrice); // Assuming the UnitPrice is a string, convert it to a number if needed
      const productValue = quantity * unitPrice;
      totalValue +=
        isNaN(productValue) || productValue === 0 ? 0 : productValue;
    }

    return totalValue;
  };
  const [allProduct, setAllProduct] = useState([]);
  const getNextQuoteNumber = () => {
    // If there are no quotes, start with 0001
    if (allQuote.length === 0) {
      return "0001";
    }

    // Extract existing quote numbers and convert them to integers
    const existingQuoteNumbers = allQuote.map((quote) =>
      parseInt(quote.quote_number, 10)
    );

    // Find the maximum quote number
    const maxQuoteNumber = Math.max(...existingQuoteNumbers);

    // Calculate the next quote number
    const nextQuoteNumber = maxQuoteNumber + 1;

    // Convert the next quote number to a 4-digit style
    const nextQuoteNumberFormatted = nextQuoteNumber
      .toString()
      .padStart(4, "0");

    return nextQuoteNumberFormatted;
  };

  const today = new Date(); // Get the current date

  const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
  const [searchQueryClient, setSearchQueryClient] = useState("");
  const [searchAreaClient, setSearchAreaClient] = useState("");
  const [searchQueryProduct, setSearchQueryProduct] = useState("");
  const [newQuote, setNewQuote] = useState({
    quote_number: "",
    client_name: "",
    client_hp: "",
    secondary_sales: "",
    date: formattedDate,
    sales: userData ? userData.name : "",
    designer: "",
    phone_number: "",
    address: "",
    status: "",
    discount: 0,
    less_cv: 0,
    voucher: "",
    product: [],
  });

  const [quoteData, setQuoteData] = useState();
  const [cartToQuoteName, setCartToQuoteName] = useState(activeClient);
  const [creaatingQuote, setCreatingQuote] = useState(false);
  const styles = StyleSheet.create({
    row: {
      flexDirection: "row", // This works like display: "flex"
      justifyContent: "space-between",
      marginBottom: 10,
    },
    logoContainer: {
      flexDirection: "row", // This is to make sure the logo and text are in a row
      justifyContent: "space-between",
      alignItems: "center", // Optional: Align items vertically within the
      marginBottom: "20px",
    },
    logo: {
      width: "200px",
      marginLeft: 10,
    },
    textSmall: {
      fontSize: 10,
    },
    textHeavy: {
      fontSize: 15,
      fontWeight: "bold", // Use "normal" or "bold" for font weight
    },
  });
  const getQuote = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getQuote",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchQuote = async () => {
      const gottenQuote = await getQuote();
      if (gottenQuote) {
        const updatedQuotes = gottenQuote.quotes.map((quote) => ({
          ...quote,
          less_cv: parseFloat(quote.less_cv, 10),
          discount: parseFloat(quote.discount, 10),
          status: parseFloat(quote.status, 10),
        }));
        setAllQuote(updatedQuotes);

        if (activeDocument) {
          const activeDocumentIndex = updatedQuotes.findIndex(
            (quote) => quote.quote_number === activeDocument
          );

          setQuoteData(updatedQuotes[activeDocumentIndex]);
          clearActiveDocument();
        }
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchQuote();

    const intervalId = setInterval(fetchQuote, 2000); // 5000 milliseconds = 5 seconds

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    console.log(quoteData);
    setCartToQuoteName(activeClient);
    if (activeClient) {
      console.log("creating quote", activeClient, activeCart);
      const updatedActiveCart = activeCart.map((item) => ({
        ...item,
        area: "",
      }));
      setCreatingQuote(true);
      setNewQuote({
        ...newQuote,
        client_name: activeClient,
        product: updatedActiveCart,
      });
    }
    onLoadClearActive();
  }, []);

  const updateQuoteStatus = async (updatedQuote) => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/createAndUpdateQuote",
        {
          body: JSON.stringify(updatedQuote),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Update the state with the updated quotes
      const updatedQuotes = allQuote.map((quote) =>
        quote.quote_number === updatedQuote.quote_number ? updatedQuote : quote
      );
      setAllQuote(updatedQuotes);
    } catch (error) {
      console.error("Error updating quote status:", error);
    }
  };
  const getDateForViewing = (date) => {
    const dateString = date;
    const dateParts = dateString.split("-");
    const year = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Month is 0-based
    const day = parseInt(dateParts[2]);

    return new Date(year, month, day);
  };

  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      // Filter is already selected, remove it
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      // Filter is not selected, add it
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  const filteredProducts = allProduct.filter((product) => {
    const tags = product.tags || ""; // Default to an empty string if tags is null
    const categories = product.categories || ""; // Default to an empty string if categories is null

    const tagssAndCategories = [
      ...tags.split(" | "),
      ...categories.split(" | "),
    ];
    // console.log(selectedFilters);

    return selectedFilters.length === 0
      ? true // Return true if no filters are selected
      : selectedFilters.some((filter) => tagssAndCategories.includes(filter));
  });

  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Function to filter invoices based on search query
  const searchedProducts = filteredProducts.filter((product) => {
    const { description, name, sku } = product;
    const lowerCaseSearchQuery = searchQueryProduct.toLowerCase();

    return (
      (description &&
        description.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (name && name.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (sku && sku.toLowerCase().includes(lowerCaseSearchQuery))
    );
  });

  useEffect(() => {
    console.log(quoteData);
  }, [quoteData]);

  const [base64Images, setBase64Images] = useState([]);
  const [imageUrls, setImageUrls] = useState([]); // Replace with your array of image URLs
  const [generatingPdf, setGeneratingPdf] = useState(false);

  useEffect(() => {
    // console.log("quoteData call");
    if (quoteData) {
      // console.log(quoteData);
      const imageUrls = quoteData.product
        .slice()
        .sort((a, b) =>
          (a.area || "").localeCompare(b.area || "", undefined, {
            sensitivity: "base",
          })
        )
        .reduce((urls, product) => {
          urls.push(product.productImage);
          return urls;
        }, []);
      setImageUrls(imageUrls);
    }
  }, [quoteData]);

  useEffect(() => {
    const loadImages = async () => {
      if (imageUrls.length > 0) {
        setGeneratingPdf(true);
        try {
          const response = await axios.post(
            "https://www.salestool.oasistechnologies.asia/laravel/api/load-local-images",
            {
              images: imageUrls,
            }
          );

          if (response.data && response.data.images) {
            setBase64Images(response.data.images);
            // console.log(response.data.images);
          }
          setGeneratingPdf(false);
        } catch (error) {
          console.error("Error loading images:", error);
          setGeneratingPdf(false);
        }
      }
    };

    loadImages();
  }, [imageUrls]);

  const retreiveOriginalIndex = (sku) => {
    const getOriginalIndex = quoteData.product.findIndex(
      (pro) => pro.SKU === sku
    );

    return parseFloat(getOriginalIndex);
  };

  const renderProducts = (products) => {
    const pages = [];
    const pageCount = Math.ceil((products.length - 7) / 10) + 1;

    for (let i = 0; i < pageCount; i++) {
      const startIndex = i === 0 ? 0 : 7 + (i - 1) * 10;
      const endIndex =
        i === 0
          ? Math.min(7, products.length)
          : Math.min(startIndex + 10, products.length);
      const pageProducts = products.slice(startIndex, endIndex);
      // console.log("pagecount: ", pageCount, "i: ", i);

      pages.push(
        <Page size="A4">
          <View style={styles.container}>
            <View fixed style={styles.logoContainer}>
              <Image src={logo} style={styles.logo} />
              <View style={{ marginRight: 100 }}>
                <Text style={styles.textHeavy}>
                Oasis Technologies Asia Pte Ltd.
                </Text>
                <Text style={styles.textSmall}>
                  5008 Ang Mo Kio Avenue 5 #04-09, TechPlace II Singapore 569874
                </Text>
                <Text style={styles.textSmall}>
                   Email: enquiry@oasiswebasia.com
                </Text>
                <Text style={styles.textSmall}>+65 91997200</Text>
              </View>
            </View>
            {quoteData ? (
              <View>
                {i === 0 ? (
                  <>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        marginTop: 30,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 25,
                          fontFamily: "BoldFont",
                        }}
                      >
                        Quotation
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        marginLeft: "5%",
                        width: "90%",
                        marginTop: 20,
                      }}
                    >
                      <View style={{ width: "65%" }}>
                        <Text style={{ fontSize: 12 }}>
                          Bill To: {quoteData.client_name}
                        </Text>
                        <Text style={{ fontSize: 12 }}>
                          Contact Info: {quoteData.client_hp}
                        </Text>
                      </View>
                      <View style={{ width: "35%" }}>
                        <Text style={{ fontSize: 12 }}>
                          Quote No: S.Q{getCurrentYear()}-00
                          {quoteData.quote_number}
                        </Text>
                        <Text style={{ fontSize: 12, marginTop: 3 }}>
                          Date: {getDateForViewing(quoteData.date).getDate()}/
                          {getDateForViewing(quoteData.date).getMonth() + 1}/
                          {getDateForViewing(quoteData.date).getFullYear()}
                        </Text>
                        <Text style={{ fontSize: 12, marginTop: 3 }}>
                          Sales: {quoteData.sales}
                        </Text>
                        <Text style={{ fontSize: 12, marginTop: 3 }}>
                          Secondary: {quoteData.secondary_sales}
                        </Text>
                        <Text style={{ fontSize: 12, marginTop: 3 }}>
                          Designer: {quoteData.designer}
                        </Text>
                        <Text style={{ fontSize: 12, marginTop: 3 }}>
                          Designer Hp: {quoteData.phone_number}
                        </Text>
                      </View>
                    </View>
                    <View style={{ marginLeft: "5%", marginTop: 15 }}>
                      <Text style={{ fontSize: 12 }}>
                        Jobsite Address: {quoteData.address}
                      </Text>
                    </View>
                  </>
                ) : null}

                <View
                  style={{
                    borderTop: "2px solid black",
                    borderBottom: "2px solid black",
                    width: "90%",
                    marginLeft: "5%",
                    marginTop: 20,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottom: "2px solid black",
                      height: 35,
                    }}
                  >
                    <View
                      style={{
                        width: "20%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        SKU
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "35%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Product Description
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "12%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Area
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "6%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Qty
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "12%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Unit Price
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        (SGD)
                      </Text>
                    </View>
                    <View
                      style={{
                        width: "12%",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Total Price
                      </Text>
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          fontFamily: "BoldFont",
                        }}
                      >
                        (SGD)
                      </Text>
                    </View>
                  </View>
                  {pageProducts
                    .slice()
                    .sort((a, b) =>
                      (a.area || "").localeCompare(b.area || "", undefined, {
                        sensitivity: "base",
                      })
                    )
                    .map((product, index) => (
                      <View
                        key={index}
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 5,
                          marginBottom: 5,
                        }}
                      >
                        <View
                          style={{
                            width: "20%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 10, textAlign: "center" }}>
                            {product.SKU}
                          </Text>
                        </View>

                        <View
                          style={{
                            width: "35%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            textAlign: "left",
                            padding: "5px 0px",
                          }}
                        >
                          <View style={{ width: "calc(100% - 90px))" }}>
                            <Text
                              style={{
                                fontSize: 10,
                                textAlign: "left",
                                padding: 5,
                              }}
                            >
                              {product.productDescription
                                ? product.productDescription
                                : "None"}
                            </Text>
                          </View>
                          {base64Images[index] ? (
                            <Image
                              src={base64Images[index]} // Use the corresponding Base64 data URL
                              style={{
                                height: "40px",
                                width: "50px",
                                borderRadius: "8px",
                                objectFit: "cover",
                                marginRight: "10px",
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </View>
                        <View
                          style={{
                            width: "12%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "left",
                              padding: 5,
                            }}
                          >
                            {product.area}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "6%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 10, textAlign: "center" }}>
                            {product.quantity}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "12%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 10, textAlign: "center" }}>
                            $ {parseFloat(product.UnitPrice).toFixed(2)}
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "12%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 10, textAlign: "center" }}>
                            ${" "}
                            {parseFloat(
                              product.UnitPrice * product.quantity
                            ).toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    ))}
                </View>
                {i === pageCount - 1 ? (
                  <>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90%",
                      }}
                    >
                      <View
                        style={{
                          // width: "70%",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          width: "32%",
                          borderLeft: "2px solid black",
                          borderRight: "2px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "61%",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            SUB-TOTAL
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "40%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            $ {calculateTotalValueGiven(quoteData).toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    </View>

                    {quoteData.discount || quoteData.discount !== 0 ? (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          marginLeft: "5%",
                          width: "90%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        ></View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            height: 20,
                            width: "32%",
                            borderRight: "2px solid black",
                            borderLeft: "2px solid black",
                          }}
                        >
                          <View
                            style={{
                              width: "61%",
                              borderRight: "2px solid black",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                color: "red",
                                fontFamily: "BoldFont",
                              }}
                            >
                              DISCOUNT
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "40%",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                color: "red",
                                fontFamily: "BoldFont",
                              }}
                            >
                              - ${" "}
                              {quoteData.discount
                                ? quoteData.discount.toFixed(2)
                                : null}
                            </Text>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}

                    {quoteData.less_cv || quoteData.less_cv !== 0 ? (
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          marginLeft: "5%",
                          width: "90%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        ></View>
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            height: 20,
                            width: "32%",
                            borderRight: "2px solid black",
                            borderLeft: "2px solid black",
                          }}
                        >
                          <View
                            style={{
                              width: "61%",
                              borderRight: "2px solid black",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                color: "red",
                                fontFamily: "BoldFont",
                              }}
                            >
                              VOUCHER
                            </Text>
                          </View>
                          <View
                            style={{
                              width: "40%",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                color: "red",
                                fontFamily: "BoldFont",
                              }}
                            >
                              - $ {quoteData.less_cv.toFixed(2)}
                            </Text>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          width: "32%",
                          borderRight: "2px solid black",
                          borderLeft: "2px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "61%",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            TOTAL
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "40%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            ${" "}
                            {(
                              calculateTotalValueGiven(quoteData) -
                              quoteData.discount -
                              quoteData.less_cv
                            ).toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          width: "32%",
                          borderRight: "2px solid black",
                          borderBottom: "2px solid black",
                          borderLeft: "2px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "61%",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            9% GST
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "40%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            ${" "}
                            {(
                              (calculateTotalValueGiven(quoteData) -
                                quoteData.discount -
                                quoteData.less_cv) *
                              0.09
                            ).toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></View>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          width: "32%",
                          borderRight: "2px solid black",
                          borderBottom: "2px solid black",
                          borderLeft: "2px solid black",
                        }}
                      >
                        <View
                          style={{
                            width: "61%",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            AMOUNT PAYABLE
                          </Text>
                        </View>
                        <View
                          style={{
                            width: "40%",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              fontFamily: "BoldFont",
                            }}
                          >
                            ${" "}
                            {(
                              (calculateTotalValueGiven(quoteData) -
                                quoteData.discount -
                                quoteData.less_cv) *
                              1.09
                            ).toFixed(2)}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginLeft: "5%",
                        width: "90%",
                        marginTop: "20px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                          textDecoration: "underline",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Remarks:
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        {quoteData.remarks}
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginLeft: "5%",
                        width: "90%",
                        marginTop: "20px",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                          textDecoration: "underline",
                          fontFamily: "BoldFont",
                        }}
                      >
                        Terms and Conditions:
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        1) All cheques should be crossed and made payable to:{" "}
                        <Text
                          style={{
                            fontFamily: "BoldFont",
                          }}
                        >
                          Oasis Technologies Asia Pte Ltd.
                        </Text>
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        2) Goods sold cannot be returned & deposit are not
                        refundable
                      </Text>
                      <Text
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        3) Kindly state "Invoice no." in payment reference when
                        making payment.
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginLeft: "5%",
                        width: "90%",
                        marginTop: "20px",
                      }}
                    >
                      <View
                        style={{
                          border: "1px solid black",
                          padding: "10px",
                          width: "300px",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 12,
                            textAlign: "left",
                            fontWeight: 600,
                            textDecoration: "underline",
                            fontFamily: "BoldFont",
                          }}
                        >
                          The below is our banking details:
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            textAlign: "left",
                            marginTop: "5px",
                          }}
                        >
                          Payee Name: Oasis Technologies Asia Pte Ltd.
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            textAlign: "left",
                            marginTop: "3px",
                          }}
                        >
                          Bank: Maybank
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            textAlign: "left",
                            marginTop: "3px",
                          }}
                        >
                          Account No: 04041092841
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            textAlign: "left",
                            marginTop: "3px",
                          }}
                        >
                          
                        </Text>
                      </View>
                      <View style={{ marginLeft: "00px" }}>
                        <Image src={qrCode} style={{ height: "100px" }} />
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "90%",
                        marginLeft: "5%",
                        marginTop: "40px",
                        padding: "30px 0px",
                      }}
                    >
                      <View style={{ width: "150px" }}>
                        <View
                          style={{
                            height: 18,
                            marginTop: "3px",
                            textAlign: "center",
                          }}
                        ></View>
                        <Text
                          style={{
                            fontSize: 10,
                            marginTop: "3px",
                            textAlign: "center",
                            borderTop: "1px solid black",
                          }}
                        >
                          Received by
                        </Text>
                      </View>

                      <View style={{ width: "150px" }}>
                        <Text
                          style={{
                            fontSize: 15,
                            marginTop: "3px",
                            textAlign: "center",
                          }}
                        >
                          {quoteData.sales}
                        </Text>
                        <Text
                          style={{
                            borderTop: "1px solid black",
                            fontSize: 10,
                            marginTop: "3px",
                            textAlign: "center",
                          }}
                        >
                          Issue by
                        </Text>
                      </View>
                    </View>
                  </>
                ) : null}
              </View>
            ) : null}
          </View>
        </Page>
      );
    }

    return pages;
  };

  const PdfDocument = (
    <Document>
      {quoteData
        ? quoteData.product
          ? renderProducts(
              quoteData.product.sort((a, b) =>
                (a.area || "").localeCompare(b.area || "", undefined, {
                  sensitivity: "base",
                })
              )
            )
          : null
        : null}
    </Document>
  );
  const [clientData, setClientData] = useState([]);

  const handleSearchClientInputChange = (e) => {
    setSearchQueryClient(e.target.value);
  };

  const handleSearchAreaInputChange = (e) => {
    setSearchAreaClient(e.target.value);
  };

  const handleSearchProductInputChange = (e) => {
    setSearchQueryProduct(e.target.value);
  };

  const filteredQuote = allQuote.filter((quote) => {
    const { client_name, quote_number } = quote;
    const lowerCaseSearchQuery = searchQuery.toLowerCase();

    return (
      (client_name &&
        client_name.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (quote_number &&
        quote_number.toLowerCase().includes(lowerCaseSearchQuery))
    );
  });

  const filteredProduct = allProduct.filter((product) => {
    const { name, sku } = product;
    const lowerCaseSearchQuery = searchQueryProduct.toLowerCase();

    return (
      (name && name.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (sku && sku.toLowerCase().includes(lowerCaseSearchQuery))
    );
  });

  const filteredClient = clientData.filter((client) => {
    const { name, email } = client;
    const lowerCaseSearchQuery = searchQueryClient.toLowerCase();

    return (
      (name && name.toLowerCase().includes(lowerCaseSearchQuery)) ||
      (email && email.toLowerCase().includes(lowerCaseSearchQuery))
    );
  });

  const token = Cookies.get("token");
  const getCustomer = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getcustomer",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      const gottonCustomer = await getCustomer();
      if (gottonCustomer) {
        console.log(gottonCustomer.customers);
        setClientData(gottonCustomer.customers);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchCustomers();
  }, []);

  const quoteFileName = `Quote ${quoteData ? quoteData.quote_number : "0"}.pdf`;

  const handleClickOutsideShowClient = (event) => {
    const showClientContainer = document.getElementById(
      "show_client_container"
    );
    const clientTrigger = document.getElementById("show_client_trigger");

    if (
      showClientContainer &&
      !showClientContainer.contains(event.target) &&
      !clientTrigger.contains(event.target)
    ) {
      setShowClientList(false);
    }
  };

  useEffect(() => {
    if (showClientList) {
      window.addEventListener("click", handleClickOutsideShowClient);
    } else {
      window.removeEventListener("click", handleClickOutsideShowClient);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsideShowClient);
    };
  }, [showClientList]);

  const handleClickOutsideShowProduct = (event) => {
    const showProductContainer = document.getElementById(
      "show_product_container"
    );
    const productTrigger = document.getElementById("show_product_trigger");

    if (
      showProductContainer &&
      !showProductContainer.contains(event.target) &&
      !productTrigger.contains(event.target)
    ) {
      setShowProductList(false);
    }
  };

  useEffect(() => {
    if (showProductList) {
      window.addEventListener("click", handleClickOutsideShowProduct);
    } else {
      window.removeEventListener("click", handleClickOutsideShowProduct);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsideShowProduct);
    };
  }, [showProductList]);

  const calculateTotalValue = () => {
    let totalValue = 0;

    for (const product of newQuote.product) {
      const quantity = product.quantity;
      const unitPrice = parseFloat(product.UnitPrice); // Assuming the UnitPrice is a string, convert it to a number if needed
      const productValue = quantity * unitPrice;
      totalValue +=
        isNaN(productValue) || productValue === 0 ? 0 : productValue;
    }

    return totalValue;
  };

  const getProducts = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getproducts",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const gottenProducts = await getProducts();
      if (gottenProducts) {
        // console.log(gottenProducts.product);
        setAllProduct(gottenProducts.product);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchProducts();
  }, []);
  const [allArea, setAllArea] = useState([]);

  useEffect(() => {
    // Extract all unique tags from allProduct and store them in allArea
    const uniqueTags = allProduct.reduce((tags, product) => {
      const productTags = product.tags || ""; // Ensure productTags is not null
      productTags.split(" | ").forEach((tag) => {
        if (tag.trim() !== "" && !tags.includes(tag)) {
          tags.push(tag);
        }
      });
      return tags;
    }, []);

    setAllArea(uniqueTags);
    // console.log(uniqueTags);
  }, [allProduct]);

  const filteredArea = allArea.filter((area) => {
    const lowerCaseSearchQuery = searchAreaClient.toLowerCase();
    // console.log(searchAreaClient);
    return area && area.toLowerCase().includes(lowerCaseSearchQuery);
  });

  const [areaIsOpen, setAreaIsOpen] = useState(
    Array(newQuote.product.length).fill(false)
  );

  const toggleDropdown = (index) => {
    const updatedIsOpen = [...areaIsOpen];
    updatedIsOpen[index] = !updatedIsOpen[index];
    setAreaIsOpen(updatedIsOpen);
  };

  const handleClickOutsideShowArea = (event, index) => {
    const showAreaContainer = document.getElementById(
      `show_area_container_${index}`
    );
    const areaTrigger = document.getElementById(`show_area_trigger_${index}`);

    if (
      showAreaContainer &&
      !showAreaContainer.contains(event.target) &&
      !areaTrigger.contains(event.target)
    ) {
      setAreaIsOpen((prev) =>
        prev.map((value, i) => (i === index ? false : value))
      );
    }
  };

  useEffect(() => {
    const clickListener = (event) => {
      areaIsOpen.forEach((isOpen, index) => {
        if (isOpen) {
          handleClickOutsideShowArea(event, index);
        }
      });
    };

    if (areaIsOpen.some((isOpen) => isOpen)) {
      window.addEventListener("click", clickListener);
    } else {
      window.removeEventListener("click", clickListener);
    }

    return () => {
      window.removeEventListener("click", clickListener);
    };
  }, [areaIsOpen]);

  const uniqueSales = [...new Set(filteredQuote.map((quote) => quote.sales))];

  return (
    <div>
      <div
        style={{
          padding: "0px 20px",
          height: "7vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            margin: "0px",
            fontSize: "35px",
            fontWeight: "700",
            marginRight: "10px",
            cursor: "pointer",
          }}
          onClick={() => {
            setCreatingQuote(false);
            setquoteDataForCondition({});
            setNewQuote({
              quote_number: "",
              client_name: "",
              client_hp: "",
              date: formattedDate,
              sales: userData ? userData.name : "",
              secondary_sales: "",
              designer: "",
              phone_number: "",
              address: "",
              status: "",
              voucher: "",
              discount: 0,
              less_cv: 0,
              remarks: "",
              product: [],
            });
          }}
        >
          All Quotes
        </div>
      </div>
      {creaatingQuote ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            fontSize: "13px",
            overflow: "scroll",
            height: "95vh",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "fixed",
              bottom: 0,
              left:
                screenHeight > screenWidth
                  ? "calc(100vw * 0)"
                  : "calc(100vw * 0.13)",
              background: "white",
              width: "100%",
              height: "60px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              display: "flex",
              alignItems: "center",
              padding: "0px 20px",
              zIndex: 50,
            }}
          >
            {quoteDataForCondition ? (
              allQuote.some(
                (quote) =>
                  quote.quote_number === quoteDataForCondition.quote_number
              ) ? (
                <>
                  <div
                    style={{
                      background: "rgba(0,0,0,0.05)",
                      padding: "10px 20px",
                      marginRight: "15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      border: "1px solid lightgrey",
                    }}
                    onClick={async () => {
                      console.log(newQuote);
                      const quoteIndex = allQuote.findIndex(
                        (quote) => quote.quote_number === newQuote.quote_number
                      );

                      if (quoteIndex !== -1) {
                        const updatedAllQuote = [...allQuote];

                        newQuote.sales = userData.name;

                        updatedAllQuote[quoteIndex] = newQuote;

                        try {
                          const response = await fetch(
                            "https://www.salestool.oasistechnologies.asia/laravel/api/createAndUpdateQuote",
                            {
                              body: JSON.stringify(newQuote),
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                              },
                              method: "POST",
                            }
                          );
                          if (!response.ok) {
                            // setSignUpError(true);
                            throw new Error("Network response was not ok");
                          }

                          // Clear the form data after adding the product
                        } catch (error) {
                          // setSignUpError(true);
                          console.error("Error signing up:", error);
                        }
                        // Update the state with the updated allQuote array
                        setAllQuote(updatedAllQuote);
                        setCreatingQuote(false);
                        setQuoteData(newQuote);
                        console.log(newQuote);
                        setNewQuote({
                          quote_number: "",
                          client_name: "",
                          client_hp: "",
                          date: formattedDate,
                          sales: userData ? userData.name : "",
                          secondary_sales: "",
                          designer: "",
                          phone_number: "",
                          address: "",
                          status: "",
                          discount: 0,
                          less_cv: 0,
                          voucher: "",
                          remarks: "",
                          product: [],
                        });
                      }
                    }}
                  >
                    Update quote
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{
                      background: "rgba(0,0,0,0.05)",
                      padding: "10px 20px",
                      marginRight: "15px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      border: "1px solid lightgrey",
                    }}
                    onClick={async () => {
                      // Add the status field to the newQuote object

                      newQuote.sales = userData.name;
                      const updatedQuote = {
                        ...newQuote,
                        status: 0,
                        quote_number: getNextQuoteNumber(allQuote).toString(),
                        input_by: `${userId}`,
                      };

                      try {
                        const response = await fetch(
                          "https://www.salestool.oasistechnologies.asia/laravel/api/createAndUpdateQuote",
                          {
                            body: JSON.stringify(updatedQuote),
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${token}`,
                            },
                            method: "POST",
                          }
                        );
                        if (!response.ok) {
                          // setSignUpError(true);
                          throw new Error("Network response was not ok");
                        }
                        const json = await response.json();

                        const productArray = JSON.parse(
                          json.quote_array.product
                        );

                        const updatedAllQuote = [
                          ...allQuote,
                          {
                            ...json.quote_array,
                            product: productArray,
                          },
                        ];

                        // Update the state with the updated allQuote array
                        setAllQuote(updatedAllQuote);
                        setCreatingQuote(false);
                        setQuoteData(updatedQuote);
                        setNewQuote({
                          quote_number: "",
                          client_name: "",
                          client_hp: "",
                          date: formattedDate,
                          sales: userData ? userData.name : "",
                          secondary_sales: "",
                          designer: "",
                          phone_number: "",
                          address: "",
                          status: "",
                          discount: 0,
                          voucher: "",
                          less_cv: 0,
                          remarks: "",
                          product: [],
                        });
                        // Clear the form data after adding the product
                      } catch (error) {
                        // setSignUpError(true);
                        console.error("Error signing up:", error);
                      }
                    }}
                  >
                    Save as draft
                  </div>
                  <div
                    style={{
                      background: highlightColor,
                      color: "white",
                      padding: "10px 20px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={async () => {
                      // Add the status field to the newQuote object

                      newQuote.sales = userData.name;
                      const updatedQuote = {
                        ...newQuote,
                        status: 1,
                        quote_number: getNextQuoteNumber(allQuote).toString(),
                        input_by: `${userId}`,
                      };

                      try {
                        const response = await fetch(
                          "https://www.salestool.oasistechnologies.asia/laravel/api/createAndUpdateQuote",
                          {
                            body: JSON.stringify(updatedQuote),
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${token}`,
                            },
                            method: "POST",
                          }
                        );
                        if (!response.ok) {
                          // setSignUpError(true);
                          throw new Error("Network response was not ok");
                        }
                        const json = await response.json();
                        const productArray = JSON.parse(
                          json.quote_array.product
                        );

                        const updatedAllQuote = [
                          ...allQuote,
                          {
                            ...json.quote_array,
                            product: productArray,
                          },
                        ];
                        // Update the state with the updated allQuote array
                        setAllQuote(updatedAllQuote);
                        setCreatingQuote(false);
                        setQuoteData(updatedQuote);
                        setNewQuote({
                          quote_number: "",
                          client_name: "",
                          client_hp: "",
                          date: formattedDate,
                          sales: userData ? userData.name : "",
                          secondary_sales: "",
                          designer: "",
                          phone_number: "",
                          address: "",
                          status: "",
                          discount: 0,
                          voucher: "",
                          less_cv: 0,
                          remarks: "",
                          product: [],
                        });

                        // Clear the form data after adding the product
                      } catch (error) {
                        // setSignUpError(true);
                        console.error("Error signing up:", error);
                      }
                      // Push the updated newQuote into the allQuote array
                    }}
                  >
                    Create Quote
                  </div>
                </>
              )
            ) : (
              <>
                <div
                  style={{
                    background: "rgba(0,0,0,0.05)",
                    padding: "10px 20px",
                    marginRight: "15px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    border: "1px solid lightgrey",
                  }}
                  onClick={async () => {
                    // Add the status field to the newQuote object

                    newQuote.sales = userData.name;
                    const updatedQuote = {
                      ...newQuote,
                      status: 0,
                      quote_number: getNextQuoteNumber(allQuote).toString(),
                      input_by: `${userId}`,
                    };

                    // Push the updated newQuote into the allQuote array

                    try {
                      const response = await fetch(
                        "https://www.salestool.oasistechnologies.asia/laravel/api/createAndUpdateQuote",
                        {
                          body: JSON.stringify(updatedQuote),
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                          method: "POST",
                        }
                      );
                      if (!response.ok) {
                        // setSignUpError(true);
                        throw new Error("Network response was not ok");
                      }
                      const json = await response.json();
                      const productArray = JSON.parse(json.quote_array.product);

                      const updatedAllQuote = [
                        ...allQuote,
                        {
                          ...json.quote_array,
                          product: productArray,
                        },
                      ];
                      // Update the state with the updated allQuote array
                      setAllQuote(updatedAllQuote);
                      setCreatingQuote(false);
                      setQuoteData(updatedQuote);
                      setNewQuote({
                        quote_number: "",
                        client_name: "",
                        client_hp: "",
                        date: formattedDate,
                        sales: userData ? userData.name : "",
                        secondary_sales: "",
                        designer: "",
                        phone_number: "",
                        address: "",
                        status: "",
                        discount: 0,
                        voucher: "",
                        less_cv: 0,
                        remarks: "",
                        product: [],
                      });
                      // Clear the form data after adding the product
                    } catch (error) {
                      // setSignUpError(true);
                      console.error("Error signing up:", error);
                    }
                  }}
                >
                  Save as draft
                </div>
                <div
                  style={{
                    background: highlightColor,
                    color: "white",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={async () => {
                    // Add the status field to the newQuote object

                    newQuote.sales = userData.name;
                    const updatedQuote = {
                      ...newQuote,
                      status: 1,
                      quote_number: getNextQuoteNumber(allQuote).toString(),
                      input_by: `${userId}`,
                    };

                    try {
                      const response = await fetch(
                        "https://www.salestool.oasistechnologies.asia/laravel/api/createAndUpdateQuote",
                        {
                          body: JSON.stringify(updatedQuote),
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                          method: "POST",
                        }
                      );
                      if (!response.ok) {
                        // setSignUpError(true);
                        throw new Error("Network response was not ok");
                      }
                      const json = await response.json();
                      const productArray = JSON.parse(json.quote_array.product);

                      const updatedAllQuote = [
                        ...allQuote,
                        {
                          ...json.quote_array,
                          product: productArray,
                        },
                      ];
                      // Update the state with the updated allQuote array
                      setAllQuote(updatedAllQuote);
                      setCreatingQuote(false);
                      setQuoteData(updatedQuote);
                      setNewQuote({
                        quote_number: "",
                        client_name: "",
                        client_hp: "",
                        date: formattedDate,
                        sales: userData ? userData.name : "",
                        secondary_sales: "",
                        designer: "",
                        phone_number: "",
                        address: "",
                        status: "",
                        discount: 0,
                        voucher: "",
                        less_cv: 0,
                        remarks: "",
                        product: [],
                      });
                      // Clear the form data after adding the product
                    } catch (error) {
                      // setSignUpError(true);
                      console.error("Error signing up:", error);
                    }
                  }}
                >
                  Create Quote
                </div>
              </>
            )}
          </div>
          <div
            style={{
              width: "100%",
              borderTop: "1px solid lightgrey",
              padding: "20px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ width: "150px" }}>Customer Name</div>
              <div
                style={{ display: "flex", position: "relative" }}
                id="show_client_trigger"
              >
                <div
                  style={{
                    width: "350px",
                    height: "35px",
                    borderRadius: "10px 0px 0px 10px",
                    border: "1px solid lightgrey",
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowClientList(!showClientList)}
                >
                  <div> {newQuote.client_name}</div>
                  <div>
                    <KeyboardArrowDownIcon />
                  </div>
                </div>
                <div
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "0px 10px 10px 0px",
                    border: "1px solid rgb(209, 174, 110)",
                    background: "rgb(209, 174, 110)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <SearchIcon style={{ color: "white" }} />
                </div>
                {showClientList ? (
                  <div
                    id="show_client_container"
                    style={{
                      position: "absolute",
                      top: 40,
                      left: 0,
                      height: "350px",
                      width: "400px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 10px lightgrey",
                      zIndex: "50",
                      background: "white",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <input
                        className="search_input"
                        placeholder="Search customer"
                        type="text"
                        value={searchQueryClient}
                        onChange={handleSearchClientInputChange}
                        style={{
                          padding: "10px 12px",
                          width: "calc(100% - 24px)",
                          border: "none",
                          background: "#f0eeeb",
                          borderRadius: "8px",
                          marginRight: "5px",
                        }}
                      />
                      <div style={{ marginTop: "5px" }}>
                        {filteredClient.map((client, index) => (
                          <div
                            key={index}
                            className="client_select_dropdown"
                            style={{ padding: "5px 10px" }}
                            onClick={() => {
                              setNewQuote({
                                ...newQuote,
                                client_name: client.name,
                              });
                              setShowClientList(false);
                            }}
                          >
                            {client.name}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "calc(100% - 24px)",
                        padding: "10px 12px",
                        background: "rgb(209, 174, 110)",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "10px",
                      }}
                      onClick={() => {
                        setNewQuote({
                          ...newQuote,
                          client_name: searchQueryClient,
                        });
                        setShowClientList(false);
                      }}
                    >
                      <AddCircleIcon
                        style={{ color: "white", marginRight: "10px" }}
                      />{" "}
                      New Customer
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ width: "150px" }}>Customer Contact</div>
              <div>
                <input
                  type="text"
                  value={newQuote.client_hp}
                  onChange={(e) =>
                    setNewQuote({ ...newQuote, client_hp: e.target.value })
                  }
                  placeholder="Customer contact"
                  style={{
                    width: "340px",
                    height: "35px",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    padding: "0px 10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ width: "150px" }}>Quote number</div>
              <div>
                <div
                  type="text"
                  style={{
                    width: "350px",
                    height: "35px",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {newQuote.quote_number
                    ? `S.Q${getCurrentYear()}-00${newQuote.quote_number}`
                    : `S.Q${getCurrentYear()}-00${getNextQuoteNumber(
                        allQuote
                      )}`}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ width: "150px" }}>Quotation Date</div>
              <div>
                <input
                  type="date"
                  value={newQuote.date}
                  onChange={(e) =>
                    setNewQuote({ ...newQuote, date: e.target.value })
                  }
                  placeholder="Date"
                  style={{
                    width: "340px",
                    height: "35px",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    padding: "0px 10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", marginTop: "10px" }}>
                <div style={{ width: "150px" }}>Sales</div>
                <div>
                  <input
                    type="text"
                    value={userData ? userData.name : ""}
                    // onChange={(e) =>
                    //   setNewQuote({ ...newQuote, sales: e.target.value })
                    // }
                    placeholder="Sales"
                    style={{
                      width: "350px",
                      height: "35px",
                      borderRadius: "10px",
                      border: "1px solid lightgrey",
                      paddingLeft: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    disabled
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  marginLeft: "25px",
                }}
              >
                <div style={{ width: "150px" }}>Secondary Sales</div>
                <div>
                  <input
                    type="text"
                    value={newQuote.secondary_sales}
                    onChange={(e) =>
                      setNewQuote({
                        ...newQuote,
                        secondary_sales: e.target.value,
                      })
                    }
                    placeholder="Secondary sales person"
                    style={{
                      width: "500px",
                      height: "35px",
                      borderRadius: "10px",
                      border: "1px solid lightgrey",
                      paddingLeft: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  />
                </div>
              </div>
            </div>

            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ width: "150px" }}>Designer</div>
              <div>
                <input
                  type="text"
                  value={newQuote.designer}
                  onChange={(e) =>
                    setNewQuote({ ...newQuote, designer: e.target.value })
                  }
                  placeholder="Designer"
                  style={{
                    width: "500px",
                    height: "35px",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", marginTop: "10px" }}>
              <div style={{ width: "150px" }}>Designer Hp</div>
              <div>
                <input
                  type="number"
                  value={newQuote.phone_number}
                  onChange={(e) =>
                    setNewQuote({ ...newQuote, phone_number: e.target.value })
                  }
                  placeholder="Phone number"
                  style={{
                    width: "350px",
                    height: "35px",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "10px",
                width: "100%",
                marginBottom: "20px",
              }}
            >
              <div style={{ width: "150px" }}>Jobsite Address</div>
              <div>
                <input
                  type="text"
                  value={newQuote.address}
                  onChange={(e) =>
                    setNewQuote({ ...newQuote, address: e.target.value })
                  }
                  placeholder="Address"
                  style={{
                    width: "60vw",
                    height: "35px",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    paddingLeft: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                paddingTop: "20px",
                width: "100%",
                borderTop: "1px solid lightgrey",
              }}
            >
              <div style={{ width: "150px", marginBottom: "10px" }}>
                Products
              </div>
              <div style={{ border: "1px solid lightgrey" }}>
                <div style={{ display: "flex", padding: "10px 0px" }}>
                  <div style={{ width: "450px", marginLeft: "10px" }}>
                    Item details
                  </div>
                  <div style={{ width: "120px", marginLeft: "50px" }}>Area</div>
                  <div style={{ width: "80px", marginLeft: "50px" }}>
                    Quantity
                  </div>
                  <div style={{ width: "80px", marginLeft: "50px" }}>Rate</div>
                  <div style={{ width: "100px", marginLeft: "50px" }}>
                    Amount
                  </div>
                  <div style={{ width: "90px", marginLeft: "50px" }}>
                    Category
                  </div>
                  <div style={{ width: "90px", marginLeft: "50px" }}>Tag</div>
                </div>
                {newQuote.product.slice().map((product, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      borderTop: "1px solid lightgrey",
                      padding: "10px 0px",
                    }}
                  >
                    <div style={{ width: "450px", marginLeft: "10px" }}>
                      <input
                        type="text"
                        value={product.productName}
                        onChange={(e) => {
                          const updatedProducts = newQuote.product.map((p, i) =>
                            i === index
                              ? { ...p, productName: e.target.value }
                              : p
                          );

                          setNewQuote({
                            ...newQuote,
                            product: updatedProducts,
                          });
                        }}
                        placeholder="Product name"
                        style={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                      <textarea
                        type="text"
                        value={product.productDescription}
                        onChange={(e) => {
                          const updatedProducts = newQuote.product.map((p, i) =>
                            i === index
                              ? { ...p, productDescription: e.target.value }
                              : p
                          );

                          setNewQuote({
                            ...newQuote,
                            product: updatedProducts,
                          });
                        }}
                        placeholder="Product description"
                        style={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          resize: "none",
                        }}
                      />
                    </div>
                    <div style={{ width: "120px", marginLeft: "50px" }}>
                      <div
                        style={{ display: "flex", position: "relative" }}
                        id={`show_area_trigger_${index}`}
                      >
                        <div
                          style={{
                            width: "350px",
                            height: "35px",
                            borderRadius: "10px",
                            border: "1px solid lightgrey",
                            paddingLeft: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            console.log(filteredArea);
                            toggleDropdown(index);
                          }}
                        >
                          <div> {product.area}</div>
                          <div>
                            <KeyboardArrowDownIcon />
                          </div>
                        </div>
                        {areaIsOpen[index] ? (
                          <div
                            id={`show_area_container_${index}`}
                            style={{
                              position: "absolute",
                              top: 40,
                              left: 0,
                              height: "350px",
                              width: "300px",
                              borderRadius: "10px",
                              boxShadow: "0px 0px 10px lightgrey",
                              zIndex: "50",
                              background: "white",
                              padding: "5px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <input
                                className="search_input"
                                placeholder="Search area"
                                type="text"
                                value={searchAreaClient}
                                onChange={handleSearchAreaInputChange}
                                style={{
                                  padding: "10px 12px",
                                  width: "calc(100% - 24px)",
                                  border: "none",
                                  background: "#f0eeeb",
                                  borderRadius: "8px",
                                  marginRight: "5px",
                                }}
                              />
                              <div
                                style={{
                                  marginTop: "5px",
                                  height: "250px",
                                  overflowX: "hidden",
                                  overflowY: "auto",
                                }}
                              >
                                {Object.keys(filteredArea).map(
                                  (area, indexs) => (
                                    <div
                                      key={indexs}
                                      className="client_select_dropdown"
                                      style={{ padding: "5px 10px" }}
                                      onClick={() => {
                                        const updatedProducts =
                                          newQuote.product.map((p, i) =>
                                            i === index
                                              ? {
                                                  ...p,
                                                  area: filteredArea[area],
                                                }
                                              : p
                                          );
                                        setNewQuote({
                                          ...newQuote,
                                          product: updatedProducts,
                                        });
                                        setAreaIsOpen(
                                          Array(newQuote.product.length).fill(
                                            false
                                          )
                                        );
                                      }}
                                    >
                                      {filteredArea[area]}
                                    </div>
                                  )
                                )}
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Living",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Living
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Dining",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Dining
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Master Bedroom",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Master Bedroom
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Bedroom 2",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Bedroom 2
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Bedroom 3",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Bedroom 3
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Bedroom 4",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Bedroom 4
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Bedroom 5",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Bedroom 5
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Junior Master Bedroom",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Junior Master Bedroom
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Kitchen",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Kitchen
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Service Yard",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Service Yard
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Store",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Store
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Common Bathroom",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Common Bathroom
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Master Bathroom",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Master Bathroom
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Balcony",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Balcony
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Walkway",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Walkway
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Loft",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Loft
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Passageway",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Passageway
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Altar",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Altar
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Bomb Shelter",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Bomb Shelter
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Entrance",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Entrance
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Whole House",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Whole House
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Staircase",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Staircase
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Corridor",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Corridor
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Common Toilet 1",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Common Toilet 1
                                </div>
                                <div
                                  className="client_select_dropdown"
                                  style={{ padding: "5px 10px" }}
                                  onClick={() => {
                                    const updatedProducts =
                                      newQuote.product.map((p, i) =>
                                        i === index
                                          ? {
                                              ...p,
                                              area: "Common Toilet 2",
                                            }
                                          : p
                                      );
                                    setNewQuote({
                                      ...newQuote,
                                      product: updatedProducts,
                                    });
                                    setAreaIsOpen(
                                      Array(newQuote.product.length).fill(false)
                                    );
                                  }}
                                >
                                  Common Toilet 2
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "calc(100% - 24px)",
                                padding: "10px 12px",
                                background: "rgb(209, 174, 110)",
                                color: "white",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                borderRadius: "10px",
                              }}
                              onClick={() => {
                                const updatedProducts = newQuote.product.map(
                                  (p, i) =>
                                    i === index
                                      ? {
                                          ...p,
                                          area: searchAreaClient,
                                        }
                                      : p
                                );
                                setNewQuote({
                                  ...newQuote,
                                  product: updatedProducts,
                                });
                                setAreaIsOpen(
                                  Array(newQuote.product.length).fill(false)
                                );
                                setSearchAreaClient("");
                              }}
                            >
                              <AddCircleIcon
                                style={{
                                  color: "white",
                                  marginRight: "10px",
                                }}
                              />{" "}
                              Add New Area
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div style={{ width: "80px", marginLeft: "50px" }}>
                      <input
                        type="number"
                        value={product.quantity}
                        onChange={(e) => {
                          const updatedProducts = newQuote.product.map((p, i) =>
                            i === index ? { ...p, quantity: e.target.value } : p
                          );

                          setNewQuote({
                            ...newQuote,
                            product: updatedProducts,
                          });
                        }}
                        placeholder="0"
                        style={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                    </div>
                    <div style={{ width: "80px", marginLeft: "50px" }}>
                      <input
                        type="number"
                        value={product.UnitPrice}
                        onChange={(e) => {
                          const updatedProducts = newQuote.product.map((p, i) =>
                            i === index
                              ? { ...p, UnitPrice: e.target.value }
                              : p
                          );

                          setNewQuote({
                            ...newQuote,
                            product: updatedProducts,
                          });
                        }}
                        placeholder="0.00"
                        style={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "10px",
                          border: "1px solid lightgrey",
                          paddingLeft: "10px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                    </div>
                    <div style={{ width: "100px", marginLeft: "50px" }}>
                      ${" "}
                      {isNaN(product.quantity * product.UnitPrice) ||
                      product.quantity * product.UnitPrice === 0
                        ? "0.00"
                        : (product.quantity * product.UnitPrice).toFixed(2)}
                    </div>

                    <div style={{ width: "90px", marginLeft: "50px" }}>
                      {product.Category}
                    </div>

                    <div style={{ width: "90px", marginLeft: "50px" }}>
                      {product.Tag}
                    </div>

                    <div style={{}}>
                      <RemoveCircleOutlineIcon
                        className="remove_quote_product"
                        style={{
                          cursor: "pointer",
                          transition: "0.4s all ease-in-out",
                          fontSize: "30px",
                        }}
                        onClick={() => {
                          const updatedNewQuote = [...newQuote.product];
                          const productIndex = index;

                          updatedNewQuote.splice(productIndex, 1);

                          setNewQuote({
                            ...newQuote,
                            product: updatedNewQuote,
                          });
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "500px", position: "relative" }}>
                  <div style={{}}>
                    <div
                      id="show_product_trigger"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        background: highlightColor,
                        width: "200px",
                        padding: "10px 20px",
                        borderRadius: "10px",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        console.log(newQuote);
                        setShowProductList(!showProductList);
                      }}
                    >
                      <AddCircleIcon />
                      Add New Product
                    </div>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <textarea
                      type="text"
                      value={newQuote.remarks}
                      onChange={(e) =>
                        setNewQuote({ ...newQuote, remarks: e.target.value })
                      }
                      placeholder="Remarks"
                      style={{
                        width: "90%",
                        height: "150px",
                        borderRadius: "10px",
                        border: "1px solid lightgrey",
                        paddingLeft: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        resize: "none",
                      }}
                    />
                  </div>

                  <Modal
                    isOpen={showProductList}
                    ariaHideApp={false}
                    onRequestClose={() => {
                      setShowProductList(false);
                      setSelectedFilters([]);
                      setSearchQueryProduct("");
                    }}
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: "10000",
                      },
                      content: {
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "600px",
                        height: "600px",
                        flexDirection: "column",
                        background: "white",
                        boxShadow: "none",
                        borderRadius: "10px",
                        border: "none",
                        overflowX: "hidden",
                        overflowY: "auto",
                        padding: "none",
                        display: "flex",
                        textAlign: "center",
                        zIndex: "10001",
                      },
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "calc(100% - 40px)",
                        justifyContent: "space-between",
                        padding: "20px",
                      }}
                    >
                      <div
                        style={{
                          color: highlightColor,
                          fontWeight: "700",
                          fontSize: "24px",
                        }}
                      >
                        Add New Products
                      </div>
                      <div
                        className="close_icon"
                        style={{
                          opacity: "1",
                          transition: "all 0.3s ease-in-out",
                          border: "1px solid lightgrey",
                          borderRadius: "50%",
                          height: "40px",
                          width: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowProductList(false);
                          setSelectedFilters([]);
                          setSearchQueryProduct("");
                        }}
                      >
                        <CloseIcon />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "calc(100% - 40px)",
                        borderTop: "1px solid lightgrey",
                        padding: "5px 20px",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ fontWeight: 700 }}>Product Categories:</div>
                      <div style={{ overflowX: "scroll", display: "flex" }}>
                        {allProduct
                          .reduce((filters, product) => {
                            const tags = product.tags || ""; // Default to an empty string if tags is null
                            const categories = product.categories || ""; // Default to an empty string if categories is null

                            const tagssAndCategories = [
                              ...categories.split(" | "),
                            ];
                            // console.log(selectedFilters);
                            tagssAndCategories.forEach((filter) => {
                              if (!filters.includes(filter)) {
                                filters.push(filter);
                              }
                            });
                            return filters;
                          }, [])
                          .map((filter) => (
                            <button
                              key={filter}
                              style={{
                                margin: "0 2px",
                                background: selectedFilters.includes(filter)
                                  ? highlightColor
                                  : "white",
                                color: selectedFilters.includes(filter)
                                  ? "white"
                                  : "rgba(0,0,0,0.7)",
                                border: "none",
                                padding: "5px 15px",
                                borderRadius: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleFilterChange(filter)}
                            >
                              {filter}
                            </button>
                          ))}
                      </div>
                    </div>
                    <div style={{ padding: "0px 20px" }}>
                      <input
                        className="search_input"
                        placeholder="Search product"
                        type="text"
                        value={searchQueryProduct}
                        onChange={handleSearchProductInputChange}
                        style={{
                          padding: "10px 12px",
                          width: "calc(100% - 24px)",
                          border: "none",
                          background: "#f0eeeb",
                          borderRadius: "8px",
                          marginRight: "5px",
                        }}
                      />
                      <div
                        style={{
                          marginTop: "5px",
                          height: "380px",
                          overflowY: "scroll",
                          display: "flex",
                          width: "560px",
                          flexWrap: "wrap",
                        }}
                      >
                        {searchedProducts.map((product, index) => (
                          <div
                            key={index}
                            className="client_select_dropdown"
                            style={{
                              padding: "5px 10px",
                              textAlign: "left",
                              display: "flex",
                              alignItems: "center",
                              width: "250px",
                            }}
                            onClick={() => {
                              const updatedProduct = [...newQuote.product];
                              const newProduct = {
                                Brand: product.brand,
                                Category: product.categories,
                                CreatedAt: product.created_at,
                                productDescription: product.description,
                                Height: product.height,
                                id: product.id,
                                productImage: product.img_src,
                                Length: product.length,
                                AttributeColor: product.luminance,
                                productName: product.name,
                                Size: product.size,
                                SKU: product.sku,
                                Stock: product.stock,
                                Tag: product.tags,
                                UnitPrice: product.unit_price,
                                UpdatedAt: product.updated_at,
                                Weight: product.weight,
                                Width: product.width,
                                quantity: 1,
                                area: "",
                              };
                              updatedProduct.push(newProduct);
                              setNewQuote({
                                ...newQuote,
                                product: updatedProduct,
                              });
                              setShowProductList(false);
                              setSelectedFilters([]);
                              setSearchQueryProduct("");
                            }}
                          >
                            <img
                              src={product.img_src}
                              onError={(e) => {
                                e.target.src = fallbackImage;
                              }}
                              style={{
                                width: "70px",
                                height: "70px",
                                objectFit: "cover",
                                borderRadius: "8px",
                              }}
                            />
                            <div style={{ marginLeft: "8px" }}>
                              <div
                                className="client_select_dropdown_text"
                                style={{
                                  color: highlightColor,
                                  fontWeight: "600",
                                  fontSize: "18px",
                                }}
                              >
                                {product.name}
                              </div>
                              <div
                                className="client_select_dropdown_text"
                                style={{
                                  color: "grey",
                                  fontWeight: "400",
                                  fontSize: "13px",
                                }}
                              >
                                {product.sku}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Modal>
                </div>
                <div style={{ width: "500px" }}>
                  <div
                    style={{
                      background: "rgba(0,0,0,0.03)",
                      width: "100%",
                      borderRadius: "10px",
                      marginTop: "10px",
                      paddingBottom: "20px",
                      border: "1px solid lightgrey",
                      marginBottom: "80px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          marginLeft: "20px",
                          marginTop: "20px",
                        }}
                      >
                        Subtotal
                      </div>
                      <div style={{ marginRight: "40px", marginTop: "20px" }}>
                        $ {calculateTotalValue().toFixed(2)}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        style={{
                          width: "300px",
                          marginLeft: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div style={{ display: "flex" }}>
                            Discount
                            <input
                              type="checkbox"
                              checked={discountIsOpen}
                              onChange={(e) => {
                                setDiscountIsOpen(e.target.checked);
                                if (e.target.checked === false) {
                                  console.log("it is false");
                                  setNewQuote({
                                    ...newQuote,
                                    discount: 0,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div
                            style={{
                              color: highlightColor,
                              fontSize: "12px",
                              marginTop: "2px",
                            }}
                          >
                            Apply in deducting amount
                          </div>
                        </div>
                        {discountIsOpen ? (
                          <input
                            type="number"
                            step="0.01" // Use step to allow two decimal places
                            value={newQuote.discount}
                            onChange={(e) => {
                              const value = e.target.value.trim(); // Trim whitespace

                              if (value === "") {
                                // Handle an empty input
                                setNewQuote({
                                  ...newQuote,
                                  discount: "", // Set it as an empty string
                                });
                              } else {
                                const parsedValue = parseFloat(value);
                                if (!isNaN(parsedValue)) {
                                  setNewQuote({
                                    ...newQuote,
                                    discount: parsedValue,
                                  });
                                }
                              }
                            }}
                            onBlur={() => {
                              if (newQuote.discount === "") {
                                setNewQuote({
                                  ...newQuote,
                                  discount: 0,
                                });
                              }
                            }}
                            style={{
                              width: "100px",
                              height: "35px",
                              borderRadius: "10px",
                              border: "1px solid lightgrey",
                              paddingLeft: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          />
                        ) : newQuote.discount ? (
                          <input
                            type="number"
                            step="0.01" // Use step to allow two decimal places
                            value={newQuote.discount}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              if (!isNaN(value)) {
                                setNewQuote({
                                  ...newQuote,
                                  discount: value,
                                });
                              }
                            }}
                            onBlur={() => {
                              if (newQuote.discount === "") {
                                setNewQuote({
                                  ...newQuote,
                                  discount: 0,
                                });
                              }
                            }}
                            style={{
                              width: "100px",
                              height: "35px",
                              borderRadius: "10px",
                              border: "1px solid lightgrey",
                              paddingLeft: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      {discountIsOpen ? (
                        <div style={{ marginRight: "40px" }}>
                          ${" "}
                          {(calculateTotalValue() - newQuote.discount).toFixed(
                            2
                          )}
                        </div>
                      ) : newQuote.discount ? (
                        <div style={{ marginRight: "40px" }}>
                          ${" "}
                          {(calculateTotalValue() - newQuote.discount).toFixed(
                            2
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div
                        style={{
                          width: "300px",
                          marginLeft: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <div style={{ display: "flex" }}>
                            Voucher
                            <input
                              type="checkbox"
                              checked={voucherIsOpen}
                              onChange={(e) => {
                                setVoucherIsOpen(e.target.checked);
                                if (e.target.checked === false) {
                                  console.log("it is false");
                                  setNewQuote({
                                    ...newQuote,
                                    voucher: "",
                                    less_cv: 0,
                                  });
                                }
                              }}
                            />
                          </div>
                          <div
                            style={{
                              color: highlightColor,
                              fontSize: "12px",
                              marginTop: "2px",
                            }}
                          >
                            Apply voucher in deducting amount
                          </div>
                        </div>
                        {voucherIsOpen ? (
                          <input
                            placeholder="Voucher ID"
                            value={newQuote.voucher}
                            onChange={(e) => {
                              setNewQuote({
                                ...newQuote,
                                voucher: e.target.value,
                              });
                            }}
                            style={{
                              width: "100px",
                              height: "35px",
                              borderRadius: "10px",
                              border: "1px solid lightgrey",
                              paddingLeft: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          />
                        ) : newQuote.less_cv ? (
                          <input
                            placeholder="Voucher ID"
                            value={newQuote.voucher}
                            onChange={(e) => {
                              setNewQuote({
                                ...newQuote,
                                voucher: e.target.value,
                              });
                            }}
                            style={{
                              width: "100px",
                              height: "35px",
                              borderRadius: "10px",
                              border: "1px solid lightgrey",
                              paddingLeft: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        {voucherIsOpen ? (
                          <input
                            type="number"
                            step="0.01" // Use step to allow two decimal places
                            value={newQuote.less_cv}
                            onChange={(e) => {
                              const value = e.target.value.trim(); // Trim whitespace

                              if (value === "") {
                                // Handle an empty input
                                setNewQuote({
                                  ...newQuote,
                                  less_cv: "", // Set it as an empty string
                                });
                              } else {
                                const parsedValue = parseFloat(value);
                                if (!isNaN(parsedValue)) {
                                  setNewQuote({
                                    ...newQuote,
                                    less_cv: parsedValue,
                                  });
                                }
                              }
                            }}
                            onBlur={() => {
                              if (newQuote.less_cv === "") {
                                setNewQuote({
                                  ...newQuote,
                                  less_cv: 0,
                                });
                              }
                            }}
                            style={{
                              width: "50px",
                              height: "35px",
                              borderRadius: "10px",
                              border: "1px solid lightgrey",
                              paddingLeft: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          />
                        ) : newQuote.less_cv ? (
                          <input
                            type="number"
                            step="0.01" // Use step to allow two decimal places
                            value={newQuote.less_cv}
                            onChange={(e) => {
                              const value = e.target.value.trim(); // Trim whitespace

                              if (value === "") {
                                // Handle an empty input
                                setNewQuote({
                                  ...newQuote,
                                  less_cv: "", // Set it as an empty string
                                });
                              } else {
                                const parsedValue = parseFloat(value);
                                if (!isNaN(parsedValue)) {
                                  setNewQuote({
                                    ...newQuote,
                                    less_cv: parsedValue,
                                  });
                                }
                              }
                            }}
                            onBlur={() => {
                              if (newQuote.less_cv === "") {
                                setNewQuote({
                                  ...newQuote,
                                  less_cv: 0,
                                });
                              }
                            }}
                            style={{
                              width: "50px",
                              height: "35px",
                              borderRadius: "10px",
                              border: "1px solid lightgrey",
                              paddingLeft: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      {voucherIsOpen ? (
                        <div style={{ marginRight: "40px" }}>
                          ${" "}
                          {(
                            calculateTotalValue() -
                            newQuote.discount -
                            newQuote.less_cv
                          ).toFixed(2)}
                        </div>
                      ) : newQuote.less_cv ? (
                        <div style={{ marginRight: "40px" }}>
                          ${" "}
                          {(
                            calculateTotalValue() -
                            newQuote.discount -
                            newQuote.less_cv
                          ).toFixed(2)}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ width: "150px", marginLeft: "20px" }}>
                        Total
                      </div>
                      <div style={{ marginRight: "40px" }}>
                        ${" "}
                        {(
                          calculateTotalValue() -
                          newQuote.discount -
                          newQuote.less_cv
                        ).toFixed(2)}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div style={{ width: "150px", marginLeft: "20px" }}>
                        GST
                      </div>
                      <div style={{ marginRight: "40px" }}>
                        ${" "}
                        {(
                          (calculateTotalValue() -
                            newQuote.discount -
                            newQuote.less_cv) *
                          0.09
                        ).toFixed(2)}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                    >
                      <div style={{ width: "250px", marginLeft: "20px" }}>
                        Account payable
                      </div>
                      <div
                        style={{
                          marginRight: "40px",
                          fontSize: "25px",
                          fontWeight: "700",
                        }}
                      >
                        ${" "}
                        {(
                          (calculateTotalValue() -
                            newQuote.discount -
                            newQuote.less_cv) *
                          1.09
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ color: "transparent", height: "200px" }}>asd</div>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ width: "25%", borderTop: "1px solid lightgrey" }}>
            <div
              style={{
                padding: "0px 10px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderBottom: "1px solid lightgrey",
                // background:"red"
              }}
            >
              <input
                className="search_input"
                placeholder="Search all Quotes"
                type="text"
                value={searchQuery}
                onChange={handleSearchInputChange}
                style={{
                  padding: "8px 20px",
                  width: "100%",
                  border: "none",
                  background: "#f0eeeb",
                  borderRadius: "8px",
                  marginRight: "5px",
                }}
              />
              <div
                style={{
                  background: highlightColor,
                  borderRadius: "7px",
                  display: "flex",
                  alignItems: "center",
                  padding: "6px 15px 6px 10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCartToQuoteName(null);
                  setCreatingQuote(true);
                }}
              >
                <AddIcon style={{ color: "white", fontSize: "18px" }} />
                <div
                  style={{
                    color: "white",
                    fontSize: "13px",
                    marginBottom: "2.5px",
                  }}
                >
                  New
                </div>
              </div>
            </div>
            {userData ? (
              userData.role !== "Sales" ? (
                <div
                  style={{
                    height: "5vh",
                    borderBottom: "1px solid lightgrey",
                    padding: "10px 10px 5px 10px",
                  }}
                >
                  <select
                    style={{
                      padding: "8px 20px",
                      width: "100%",
                      border: "none",
                      background: "#f0eeeb",
                      borderRadius: "8px",
                    }}
                    value={selectedUser}
                    onChange={(e) => setSelectedUser(e.target.value)}
                  >
                    <option value="">Everybody</option>
                    {uniqueSales.map((sales) => (
                      <option key={sales} value={sales}>
                        {sales}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null
            ) : null}
            <div
              style={{
                overflowY: "scroll",
                height: userData
                  ? userData.role !== "Sales"
                    ? "73vh"
                    : "78vh"
                  : "78vh",
              }}
            >
              {filteredQuote
                .filter(
                  (quote) => selectedUser === "" || quote.sales === selectedUser
                )
                .map((quote, index) => {
                  if (userData && userData.role === "Sales") {
                    if (parseFloat(quote.input_by) === userId) {
                      return (
                        <div
                          className="invoice_item"
                          key={index}
                          onClick={() => {
                            console.log(quote);

                            setQuoteData(quote);
                          }}
                          style={{
                            background:
                              quoteData === quote ? `#f2efeb` : "white",
                            padding: "8px 0px",
                            borderBottom: "1px solid lightgrey",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "0px 15px",
                            }}
                          >
                            <div style={{ fontSize: "18px" }}>
                              {quote.client_name}
                            </div>
                            <div>
                              SGD{" "}
                              {(
                                (quote.product.reduce(
                                  (acc, item) =>
                                    acc +
                                    (item.quantity
                                      ? parseFloat(item.quantity)
                                      : 0) *
                                      (item.UnitPrice
                                        ? parseFloat(item.UnitPrice)
                                        : 0),
                                  0
                                ) -
                                  parseFloat(quote.discount) -
                                  parseFloat(quote.less_cv)) *
                                1.09
                              ).toFixed(2)}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              padding: "0px 15px",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "13px",
                                color: highlightColor,
                                fontWeight: "600",
                              }}
                            >
                              {quote.status === 1
                                ? `S.Q${getCurrentYear()}-00${
                                    quote.quote_number
                                  }`
                                : quote.status === 0
                                ? "Draft"
                                : "Void"}
                            </div>
                            <div style={{}}>{quote.date}</div>
                          </div>
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  } else {
                    return (
                      <div
                        className="invoice_item"
                        key={index}
                        onClick={() => {
                          console.log(quote);

                          setQuoteData(quote);
                        }}
                        style={{
                          background: quoteData === quote ? `#f2efeb` : "white",
                          padding: "8px 0px",
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "0px 15px",
                          }}
                        >
                          <div style={{ fontSize: "18px" }}>
                            {quote.client_name}
                          </div>
                          <div>
                            SGD{" "}
                            {(
                              (quote.product.reduce(
                                (acc, item) =>
                                  acc +
                                  (item.quantity
                                    ? parseFloat(item.quantity)
                                    : 0) *
                                    (item.UnitPrice
                                      ? parseFloat(item.UnitPrice)
                                      : 0),
                                0
                              ) -
                                parseFloat(quote.discount) -
                                parseFloat(quote.less_cv)) *
                              1.09
                            ).toFixed(2)}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            padding: "0px 15px",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "13px",
                              color: highlightColor,
                              fontWeight: "600",
                            }}
                          >
                            {quote.status === 1
                              ? `S.Q${getCurrentYear()}-00${quote.quote_number}`
                              : quote.status === 0
                              ? "Draft"
                              : "Void"}
                          </div>
                          <div style={{}}>{quote.date}</div>
                        </div>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          <div
            style={{
              width: "75%",
              borderTop: "1px solid lightgrey",
              borderLeft: "1px solid lightgrey",
            }}
          >
            <div
              style={{
                display: "flex",
                height: "50px",
                alignItems: "center",
                borderBottom: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 20px",
                  borderRight: "1px solid lightgrey",
                  height: "50px",
                }}
              >
                <div
                  className="remove_text_decoration"
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  S.Q{getCurrentYear()}-00
                  {quoteData ? quoteData.quote_number : null}
                </div>
              </div>
              {quoteData ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 20px",
                    borderRight: "1px solid lightgrey",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCreatingQuote(true);
                    console.log(
                      allQuote.some(
                        (quote) => quote.quote_number === quoteData.quote_number
                      )
                    );
                    setquoteDataForCondition(quoteData);
                    setNewQuote(quoteData);
                    if (quoteData.discount !== 0) {
                      setDiscountIsOpen(true);
                    }

                    if (quoteData.less_cv !== 0) {
                      setVoucherIsOpen(true);
                    }
                  }}
                >
                  <BorderColorIcon
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      fontSize: "18px",
                      marginRight: "4px",
                    }}
                  />
                  <div
                    className="remove_text_decoration"
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Edit Quote
                  </div>
                </div>
              ) : null}

              {/* {quoteData ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 20px",
                    borderRight: "1px solid lightgrey",
                    height: "50px",
                  }}
                >
                  <MailOutlineIcon
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      fontSize: "18px",
                      marginRight: "4px",
                    }}
                  />
                  <div
                    className="remove_text_decoration"
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Send Mail
                  </div>
                </div>
              ) : null} */}

              {quoteData ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 20px",
                    borderRight: "1px solid lightgrey",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    convertInvoice(quoteData);
                    onConvertInvoice();
                  }}
                >
                  <ContentPasteGoIcon
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      fontSize: "18px",
                      marginRight: "4px",
                    }}
                  />
                  <div
                    className="remove_text_decoration"
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Convert Invoice
                  </div>
                </div>
              ) : null}

              {quoteData && !invoiceExist(quoteData.quote_number) ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 20px",
                    borderRight: "1px solid lightgrey",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={async (e) => {
                    e.preventDefault();
                    console.log(quoteData.id);
                    const token = Cookies.get("token");

                    const quoteIndex = allQuote.findIndex(
                      (quote) => quote.id === quoteData.id
                    );
                    const removingQuote = [...allQuote];
                    removingQuote.splice(quoteIndex, 1);
                    setAllQuote(removingQuote);

                    try {
                      const response = await axios.delete(
                        `https://www.salestool.oasistechnologies.asia/laravel/api/deletequote/${quoteData.id}`,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      );
                      setQuoteData();
                    } catch (error) {
                      console.log(error);
                      setQuoteData();
                    }
                  }}
                >
                  <BlockIcon
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      fontSize: "18px",
                      marginRight: "4px",
                    }}
                  />
                  <div
                    className="remove_text_decoration"
                    style={{
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: "600",
                      fontSize: "14px",
                    }}
                  >
                    Delete Quote
                  </div>
                </div>
              ) : null}

              {quoteData ? (
                generatingPdf ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 20px",
                      borderRight: "1px solid lightgrey",
                      height: "50px",
                    }}
                  >
                    <PictureAsPdfIcon
                      style={{
                        color: "rgba(0,0,0,0.7)",
                        fontSize: "18px",
                        marginRight: "4px",
                      }}
                    />
                    <div
                      className="remove_text_decoration"
                      style={{
                        color: "rgba(0,0,0,0.7)",
                        fontWeight: "600",
                        fontSize: "14px",
                      }}
                    >
                      Generating PDF
                    </div>
                  </div>
                ) : (
                  <PDFDownloadLink
                    document={PdfDocument}
                    fileName={quoteFileName}
                    style={{ textDecoration: "none" }}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <></>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0px 20px",
                            borderRight: "1px solid lightgrey",
                            height: "50px",
                          }}
                        >
                          <PictureAsPdfIcon
                            style={{
                              color: "rgba(0,0,0,0.7)",
                              fontSize: "18px",
                              marginRight: "4px",
                            }}
                          />
                          <div
                            className="remove_text_decoration"
                            style={{
                              color: "rgba(0,0,0,0.7)",
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            PDF Download
                          </div>
                        </div>
                      )
                    }
                  </PDFDownloadLink>
                )
              ) : null}

              {quoteData ? (
                quoteData.status === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 20px",
                      borderRight: "1px solid lightgrey",
                      height: "50px",
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: "600",
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      updateQuoteStatus({
                        ...quoteData,
                        status: 1,
                      });
                    }}
                  >
                    Create Quote
                  </div>
                ) : null
              ) : null}
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                overflowY: "scroll",
                height: "78vh",
              }}
            >
              {quoteData ? (
                <div
                  style={{
                    marginTop: "30px",
                    width: "600px",
                    // boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                    padding: "30px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      justifyContent: "space-between",
                    }}
                  >
                    <img src={logo} style={styles.logo} />
                    <div style={{ marginRight: 100 }}>
                      <div style={styles.textHeavy}>
                      Oasis Technologies Asia Pte Ltd.
                      </div>
                      <div style={styles.textSmall}>
                        5008 Ang Mo Kio Avenue 5 #04-09, TechPlace II Singapore 569874
                      </div>
                      <div style={styles.textSmall}>
                         Email: enquiry@oasiswebasia.com
                      </div>
                      <div style={styles.textSmall}>
                        +65 91997200
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: 30,
                    }}
                  >
                    <div style={{ fontSize: 25, fontWeight: "bolder" }}>
                      Quotation
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginLeft: "5%",
                      width: "90%",
                      marginTop: 20,
                    }}
                  >
                    <div style={{ width: "65%" }}>
                      <div style={{ fontSize: 12 }}>
                        Bill To: {quoteData.client_name}
                      </div>
                      <div style={{ fontSize: 12 }}>
                        Contact Info: {quoteData.client_hp}
                      </div>
                    </div>
                    <div style={{ width: "35%" }}>
                      <div style={{ fontSize: 12 }}>
                        Quote No: S.Q{getCurrentYear()}-00
                        {quoteData.quote_number}
                      </div>
                      <div style={{ fontSize: 12, marginTop: 3 }}>
                        Date: {getDateForViewing(quoteData.date).getDate()}/
                        {getDateForViewing(quoteData.date).getMonth() + 1}/
                        {getDateForViewing(quoteData.date).getFullYear()}
                      </div>
                      <div style={{ fontSize: 12, marginTop: 3 }}>
                        Sales: {quoteData.sales}
                      </div>
                      <div style={{ fontSize: 12, marginTop: 3 }}>
                        Secondary: {quoteData.secondary_sales}
                      </div>
                      <div style={{ fontSize: 12, marginTop: 3 }}>
                        Designer: {quoteData.designer}
                      </div>
                      <div style={{ fontSize: 12, marginTop: 3 }}>
                        Designer Hp: {quoteData.phone_number}
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: "5%", marginTop: 15 }}>
                    <div style={{ fontSize: 12 }}>
                      Jobsite Address: {quoteData.address}
                    </div>
                  </div>
                  <div
                    style={{
                      borderTop: "2px solid black",
                      borderBottom: "2px solid black",
                      width: "90%",
                      marginLeft: "5%",
                      marginTop: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: "2px solid black",
                        alignItems: "center",
                        height: "35px",
                        fontWeight: "bolder",
                      }}
                    >
                      <div
                        style={{
                          width: "80px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          SKU
                        </div>
                      </div>
                      <div
                        style={{
                          width: "200px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          Product Description
                        </div>
                      </div>
                      <div
                        style={{
                          width: "80px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          Area
                        </div>
                      </div>
                      <div
                        style={{
                          width: "30px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          Qty
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          Unit Price
                        </div>
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          (SGD)
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: "35px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          Total Price
                        </div>
                        <div style={{ fontSize: 12, textAlign: "center" }}>
                          (SGD)
                        </div>
                      </div>
                    </div>

                    {quoteData.product
                      .slice()
                      .sort((a, b) =>
                        (a.area || "").localeCompare(b.area || "", undefined, {
                          sensitivity: "base",
                        })
                      )
                      .map((product, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "80px",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                padding: "5px 0px",
                              }}
                            >
                              {product.SKU}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "200px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              textAlign: "left",
                              padding: "5px 0px",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "left",
                                padding: 5,
                              }}
                            >
                              {product.productDescription
                                ? product.productDescription
                                : "None"}
                            </div>
                            {product.productImage ? (
                              <img
                                src={product.productImage}
                                onError={(e) => {
                                  e.target.src = fallbackImage;
                                }}
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "8px",
                                  marginRight: "5px",
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                          <div
                            style={{
                              width: "80px",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                padding: 5,
                              }}
                            >
                              {product.area}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "30px",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                padding: 5,
                              }}
                            >
                              {product.quantity}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "70px",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                padding: 5,
                              }}
                            >
                              $ {parseFloat(product.UnitPrice).toFixed(2)}
                            </div>
                          </div>
                          <div
                            style={{
                              width: "70px",
                              flexDirection: "column",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: 10,
                                textAlign: "center",
                                padding: 5,
                              }}
                            >
                              ${" "}
                              {parseFloat(
                                product.UnitPrice * product.quantity
                              ).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: 20,
                      marginLeft: "5%",
                      width: "90.2%",
                    }}
                  >
                    <div
                      style={{
                        width: "320px",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        width: "175px",
                        fontWeight: "bolder",
                      }}
                    >
                      <div
                        style={{
                          width: "103px",
                          borderRight: "2px solid black",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          SUB-TOTAL
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          $ {calculateTotalValueGiven(quoteData).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>

                  {quoteData.discount !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90.2%",
                      }}
                    >
                      <div
                        style={{
                          width: "320px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          borderRight: "2px solid black",
                          borderLeft: "2px solid black",
                          width: "175px",
                          fontWeight: "bolder",
                        }}
                      >
                        <div
                          style={{
                            width: "103px",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              color: "red",
                              paddingTop: 3,
                            }}
                          >
                            DISCOUNT
                          </div>
                        </div>
                        <div
                          style={{
                            width: "70px",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              color: "red",
                              paddingTop: 3,
                            }}
                          >
                            - $ {quoteData.discount.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {quoteData.less_cv !== 0 ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        marginLeft: "5%",
                        width: "90.2%",
                      }}
                    >
                      <div
                        style={{
                          width: "320px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      ></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: 20,
                          borderRight: "2px solid black",
                          borderLeft: "2px solid black",
                          width: "175px",
                          fontWeight: "bolder",
                        }}
                      >
                        <div
                          style={{
                            width: "103px",
                            borderRight: "2px solid black",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              color: "red",
                              paddingTop: 3,
                            }}
                          >
                            VOUCHER
                          </div>
                        </div>
                        <div
                          style={{
                            width: "70px",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              fontSize: 10,
                              textAlign: "center",
                              color: "red",
                              paddingTop: 3,
                            }}
                          >
                            - $ {quoteData.less_cv.toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: 20,
                      marginLeft: "5%",
                      width: "90.2%",
                    }}
                  >
                    <div
                      style={{
                        width: "320px",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        borderRight: "2px solid black",
                        borderLeft: "2px solid black",
                        width: "175px",
                        fontWeight: "bolder",
                      }}
                    >
                      <div
                        style={{
                          width: "103px",
                          borderRight: "2px solid black",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          TOTAL
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          ${" "}
                          {(
                            calculateTotalValueGiven(quoteData) -
                            quoteData.discount -
                            quoteData.less_cv
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: 20,
                      marginLeft: "5%",
                      width: "90.2%",
                    }}
                  >
                    <div
                      style={{
                        width: "320px",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        borderRight: "2px solid black",
                        borderBottom: "2px solid black",
                        borderLeft: "2px solid black",
                        width: "175px",
                        fontWeight: "bolder",
                      }}
                    >
                      <div
                        style={{
                          width: "103px",
                          borderRight: "2px solid black",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          9% GST
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          ${" "}
                          {(
                            (calculateTotalValueGiven(quoteData) -
                              quoteData.discount -
                              quoteData.less_cv) *
                            0.09
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      height: 20,
                      marginLeft: "5%",
                      width: "90.2%",
                    }}
                  >
                    <div
                      style={{
                        width: "320px",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    ></div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        height: 20,
                        borderRight: "2px solid black",
                        borderBottom: "2px solid black",
                        borderLeft: "2px solid black",
                        width: "175px",
                        fontWeight: "bolder",
                      }}
                    >
                      <div
                        style={{
                          width: "103px",
                          borderRight: "2px solid black",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          AMOUNT PAYABLE
                        </div>
                      </div>
                      <div
                        style={{
                          width: "70px",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 10,
                            textAlign: "center",
                            paddingTop: 3,
                          }}
                        >
                          ${" "}
                          {(
                            (calculateTotalValueGiven(quoteData) -
                              quoteData.discount -
                              quoteData.less_cv) *
                            1.09
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft: "5%",
                      width: "90%",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        textAlign: "left",
                        textDecoration: "underline",
                        fontWeight: "bolder",
                      }}
                    >
                      Remarks:
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        textAlign: "left",
                        marginTop: "3px",
                      }}
                    >
                      {quoteData.remarks}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      marginLeft: "5%",
                      width: "90%",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        textAlign: "left",
                        textDecoration: "underline",
                        fontWeight: "bolder",
                      }}
                    >
                      Terms and Conditions:
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        textAlign: "left",
                        marginTop: "3px",
                      }}
                    >
                      1) All cheques should be crossed and made payable to:{" "}
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        Oasis Technologies Asia Pte Ltd.
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        textAlign: "left",
                        marginTop: "3px",
                      }}
                    >
                      2) Goods sold cannot be returned & deposit are not
                      refundable
                    </div>
                    <div
                      style={{
                        fontSize: 10,
                        textAlign: "left",
                        marginTop: "3px",
                      }}
                    >
                      3) Kindly state "Invoice no." in payment reference when
                      making payment.
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginLeft: "5%",
                      width: "90%",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ border: "1px solid black", padding: "10px" }}>
                      <div
                        style={{
                          fontSize: 12,
                          textAlign: "left",
                          fontWeight: 600,
                          textDecoration: "underline",
                        }}
                      >
                        The below is our banking details:
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "5px",
                        }}
                      >
                        Payee Name: Oasis Technologies Asia Pte Ltd.
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        Bank: Maybank
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        Account No: 04041092841
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          textAlign: "left",
                          marginTop: "3px",
                        }}
                      >
                        
                      </div>
                    </div>
                    <div>
                      <img src={qrCode} style={{ height: "100px" }} />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "90%",
                      marginLeft: "5%",
                      marginTop: "70px",
                      padding: "30px 0px",
                    }}
                  >
                    <div style={{ width: "150px" }}>
                      <div
                        style={{
                          height: 18,
                          marginTop: "3px",
                          textAlign: "center",
                        }}
                      />
                      <div
                        style={{
                          fontSize: 10,
                          marginTop: "3px",
                          textAlign: "center",
                          borderTop: "1px solid black",
                        }}
                      >
                        Received by
                      </div>
                    </div>

                    <div style={{ width: "150px" }}>
                      <div
                        style={{
                          fontSize: 15,
                          marginTop: "3px",
                          textAlign: "center",
                        }}
                      >
                        {quoteData.sales}
                      </div>
                      <div
                        style={{
                          borderTop: "1px solid black",
                          fontSize: 10,
                          marginTop: "3px",
                          textAlign: "center",
                        }}
                      >
                        Issue by
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              {/* <PDFViewer style={{ width: "100%", height: "calc(93vh - 50px)" }}>
                {PdfDocument}
              </PDFViewer> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Quote;
