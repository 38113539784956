import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import Modal from "react-modal";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { DateRangePicker } from "react-date-range";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import appInfo from "./appInfo";

const Customer = ({ userId, setRight, setActiveDocument, highlightColor }) => {
  ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const token = Cookies.get("token");

  const [searchText, setSearchText] = useState("");
  const [userData, setUserData] = useState();

  const [clientData, setClientData] = useState([]);
  const [clientData1, setClientData1] = useState([]);
  const [notesModal, setNotesModal] = useState(false);

  const [typingNotes, setTypingNotes] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [allInvoice, setAllInvoice] = useState([]);
  const [allQuote, setAllQuote] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: new Date("2023-01-01"),
    endDate: new Date("2024-12-31"),
    key: "selection",
  });
  const filterDataByDateRange = (data, dateRange) => {
    return data.filter((item) => {
      const itemDate = new Date(item.date); // Convert the item's date string to a Date object
      return itemDate >= dateRange.startDate && itemDate <= dateRange.endDate;
    });
  };
  const handleNameClick = (params) => {
    const id = params.row.id;
    const selectedClient = clientData.find((row) => row.id === id);
    setSelectedClient(selectedClient);
    console.log(selectedClient);
    setClientSelected(true);
  };

  const fetchUserData = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getuserdata",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      setUserData(data.user_data);
      console.log(data.user_data);
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  const returnUserData = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getuserdata",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data.user_data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    if (!token) {
      Cookies.remove("token");
      navigate("/"); // programmatically navigate to /home
    }
    fetchUserData();
  }, []);

  const now = new Date();

  const getInvoice = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getInvoice",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };
  const getQuote = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getQuote",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  const handlePushNotes = async (e) => {
    e.preventDefault();
    const customerIndex = clientData.findIndex(
      (clients) => clients.id === selectedClient.id
    );

    // Ensure that notes is an array, even if it's null
    if (clientData[customerIndex].notes === null) {
      clientData[customerIndex].notes = [];
    }

    const now = new Date();
    const pushingNotes = {
      description: typingNotes,
      createdOn: now.toISOString(),
    };

    clientData[customerIndex].notes.push(pushingNotes);

    // Update the client data on the server
    try {
      await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/createandupdatecustomer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(clientData[customerIndex]),
        }
      );

      // Update the state with the modified client data
      setClient([...clientData]);

      // Reset state values
      setNotesModal(false);
      setTypingNotes("");
      console.log(customerIndex);
    } catch (error) {
      console.error("Error updating client data:", error);
    }
  };

  const navigate = useNavigate();

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const getCustomer = async () => {
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/getcustomer",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {}, []);

  const [newCustomerData, setNewCustomerData] = useState({});

  const handleNewCustomerInput = (e) => {
    const { name, value } = e.target;
    setNewCustomerData({ ...newCustomerData, [name]: value });
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [signUpError, setSignUpError] = useState(false);

  const ClientProfile = ({ clientData }) => {
    console.log(clientData);
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "85vh",
        }}
      >
        <div style={{ width: "25%" }}>
          <div style={{ padding: "30px 0px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "60px",
                  width: "60px",
                  background: "#282828",
                  color: highlightColor,
                  fontSize: "25px",
                  fontWeight: "600",
                  borderRadius: "50%",
                  marginRight: "20px",
                }}
              >
                {clientData.name
                  .split(" ")
                  .slice(0, 2)
                  .map((word) => word.charAt(0))
                  .join("")
                  .toUpperCase()}
              </div>
              <div style={{ textAlign: "left" }}>
                <div style={{ fontSize: "25px", fontWeight: "600" }}>
                  {clientData.name}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    marginTop: "5px",
                  }}
                >
                  {clientData.company ? (
                    <>
                      {clientData.designation} of {clientData.company}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <hr
            style={{
              width: "100%",
              borderColor: "rgba(0,0,0,0.1)",
            }}
          />
          <div style={{ fontSize: "14px", marginTop: "35px" }}>
            <div style={{ fontSize: "18px", fontWeight: "600" }}>
              Contact Information
            </div>
            <p>email: </p>
            <p style={{ marginTop: "-12px" }}>{clientData.email}</p>
            <p style={{ marginTop: "20px" }}>Phone: </p>
            <p style={{ marginTop: "-12px" }}>{clientData.phone_number}</p>
          </div>
        </div>
        <hr
          style={{
            margin: "0px",
            width: ".1px",
            borderColor: "rgba(0,0,0,0.1)",
          }}
        />
        <div style={{ width: "50%", height: "100%" }}>
          <div
            style={{
              padding: "3%",
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              Client Notes
            </div>
            <div
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "40px",
                background: highlightColor,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setNotesModal(true)}
            >
              <AddIcon style={{ color: "white" }} />
            </div>
          </div>
          <div style={{ padding: "0px 3%", width: "94%" }}>
            {clientData.notes
              ? clientData.notes.map((notes, index) => (
                  <div
                    key={index}
                    style={{
                      width: "calc(100% - 40px)",
                      background: "White",
                      borderRadius: "12px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      marginBottom: "15px",
                      padding: "20px",
                    }}
                  >
                    <div>{notes.description}</div>
                    <div
                      style={{
                        marginTop: "15px",
                        fontSize: "12px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {formatDate(notes.createdOn)}
                    </div>
                  </div>
                ))
              : null}
          </div>
        </div>
        <hr
          style={{
            margin: "0px",
            width: ".1px",
            borderColor: "rgba(0,0,0,0.1)",
          }}
        />
        <div style={{ width: "25%", height: "100%" }}>
          <div
            style={{
              padding: "2% 5%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "45%",
              marginTop: "10px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: "600" }}>Quotes</div>
            <div
              style={{
                height: "calc(100% - 20px)",
                overflowY: "scroll",
                padding: "10px",
              }}
            >
              {clientData.quotes ? (
                clientData.quotes.map((quote, index) => (
                  <div
                    onClick={() => {
                      setActiveDocument(quote.quote_number);
                      setRight("Quote");
                    }}
                    className="invoice_item"
                    key={index}
                    style={{
                      padding: "10px 20px",
                      marginTop: "5px",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      position: "relative",
                    }}
                  >
                    Quote {quote.quote_number}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            style={{
              padding: "2% 5%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "45%",
              marginTop: "10px",
            }}
          >
            <div style={{ fontSize: "16px", fontWeight: "600" }}>Invoices</div>
            <div
              style={{
                height: "calc(100% - 20px)",
                overflowY: "scroll",
                padding: "10px",
              }}
            >
              {clientData.invoices ? (
                clientData.invoices.map((invoice, index) => (
                  <div
                    onClick={() => {
                      setActiveDocument(invoice.invoice_number);
                      setRight("Invoice");
                    }}
                    className="invoice_item"
                    key={index}
                    style={{
                      padding: "10px 20px",
                      marginTop: "5px",
                      borderRadius: "8px",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      position: "relative",
                    }}
                  >
                    Invoice {invoice.invoice_number}
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [clientSelected, setClientSelected] = useState(false);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ marginRight: "10px", marginLeft: "7px" }}></div>
          <div style={{ marginLeft: "auto" }}>
            <GridToolbar style={{ fontSize: "10px" }} />
          </div>
        </div>
      </GridToolbarContainer>
    );
  };

  const [openCreateClient, setOpenCreateClient] = useState(false);

  const [client, setClient] = useState({
    designation: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    website: "",
    company: "",
    notes: [],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClient((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    setOpenCreateClient(false);
    event.preventDefault();
    const newId = uuid();
    const name = `${client.firstName} ${client.lastName}`;
    const newClient = {
      id: newId,
      designation: client.designation,
      firstName: client.firstName,
      lastName: client.lastName,
      name: name,
      phoneNumber: client.phoneNumber,
      email: client.email,
      address: client.address,
      website: client.website,
      company: client.company,
      notes: client.notes,
    };

    try {
      const updatedData = [...clientData, newClient];
      console.log(updatedData); // Log the updated clientData array
      // Update the state with the updated client data
      setClientData(updatedData);
    } catch (error) {
      console.error(error);
    }

    setClient({
      designation: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      address: "",
      website: "",
      company: "",
      notes: [],
    });
  };

  const [searchQueryClient, setSearchQueryClient] = useState("");

  const handleSearchClientInputChange = (e) => {
    setSearchQueryClient(e.target.value);
  };

  const filteredClient = clientData.filter((client) => {
    const { name } = client;
    const lowerCaseSearchQuery = searchQueryClient.toLowerCase();

    return name && name.toLowerCase().includes(lowerCaseSearchQuery);
  });

  const [searchQueryCompany, setSearchQueryCompany] = useState("");

  const handleSearchCompanyInputChange = (e) => {
    setSearchQueryCompany(e.target.value);
  };

  const filteredCompany = filteredClient.filter((client) => {
    // console.log(searchQueryCompany, filteredClient);
    if (searchQueryCompany === "") {
      return filteredClient;
    }
    const { company } = client;
    const lowerCaseSearchQuery = searchQueryCompany.toLowerCase();

    return company && company.toLowerCase().includes(lowerCaseSearchQuery);
  });

  const [viewWithoutCompany, setViewWithoutCompany] = useState(true);

  useEffect(() => {
    // const fetchCustomers = async () => {
    //   const gottonCustomer = await getCustomer();
    //   if (gottonCustomer) {
    //     setClientData(gottonCustomer.customers);
    //   } else {
    //     console.log("Failed to fetch web sections.");
    //   }
    // };

    // fetchCustomers();

    const fetchCustomersForPopulating = async () => {
      const gottonCustomer = await getCustomer();
      if (gottonCustomer) {
        return gottonCustomer.customers;
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    const fetchInvoice = async () => {
      const changingClientData = await fetchCustomersForPopulating();
      const gottenInvoice = await getInvoice();
      const userData = await returnUserData();
      const gottenQuote = await getQuote();
      if (gottenQuote) {
        let updatedQuotes = gottenQuote.quotes.map((quote) => ({
          ...quote,
          less_cv: parseFloat(quote.less_cv),
          discount: parseFloat(quote.discount),
          status: parseFloat(quote.status),
        }));
        updatedQuotes = filterDataByDateRange(updatedQuotes, dateRange);
        console.log(updatedQuotes);
        setAllQuote(updatedQuotes);
        for (const quotetf of updatedQuotes) {
          // console.log(userData.role);
          if (userData && userData.role === "Sales") {
            console.log("sales");
            if (parseFloat(quotetf.input_by) === userId) {
              const invoiceToExistingCustomerIndex =
                changingClientData.findIndex(
                  (client) => client.name === quotetf.client_name
                );
              // console.log(invoiceToExistingCustomerIndex);

              if (invoiceToExistingCustomerIndex !== -1) {
                // console.log("add into existing client");
                // Ensure invoices is initialized as an empty array
                if (
                  !changingClientData[invoiceToExistingCustomerIndex].quotes
                ) {
                  changingClientData[invoiceToExistingCustomerIndex].quotes =
                    [];
                }
                changingClientData[invoiceToExistingCustomerIndex].quotes.push(
                  quotetf
                );
              } else {
                // console.log("create new temp client");
                const newTempCustomer = {
                  id: uuid(),
                  input_by: `${userId}`,
                  name: quotetf.client_name,
                  email: quotetf.client_name,
                  phone_number: quotetf.phone_number,
                  company: "",
                  designation: "",
                  notes: [],
                  invoices: [],
                  quotes: [],
                };

                newTempCustomer.quotes.push(quotetf);
                // console.log(newTempCustomer);
                changingClientData.push(newTempCustomer);
              }
              if (viewWithoutCompany) {
                const invoiceToExistingDesignerIndex =
                  changingClientData.findIndex(
                    (client) => client.name === quotetf.designer
                  );
                if (invoiceToExistingDesignerIndex !== -1) {
                  // console.log("add into existing client");
                  // Ensure invoices is initialized as an empty array
                  if (
                    !changingClientData[invoiceToExistingDesignerIndex].quotes
                  ) {
                    changingClientData[invoiceToExistingDesignerIndex].quotes =
                      [];
                  }
                  changingClientData[
                    invoiceToExistingDesignerIndex
                  ].quotes.push(quotetf);
                } else {
                  // console.log("create new temp client");
                  const newTempCustomer = {
                    id: uuid(),
                    input_by: `${userId}`,
                    name: quotetf.designer,
                    email: "Designer",
                    phone_number: "Designer",
                    company: "",
                    designation: "",
                    notes: [],
                    invoices: [],
                    quotes: [],
                  };

                  newTempCustomer.quotes.push(quotetf);
                  // console.log(newTempCustomer);
                  changingClientData.push(newTempCustomer);
                }
              }
            }
          } else {
            // console.log("not sales");
            const invoiceToExistingCustomerIndex = changingClientData.findIndex(
              (client) => client.name === quotetf.client_name
            );
            // console.log(invoiceToExistingCustomerIndex);

            if (invoiceToExistingCustomerIndex !== -1) {
              // console.log("add into existing client");
              // Ensure invoices is initialized as an empty array
              if (!changingClientData[invoiceToExistingCustomerIndex].quotes) {
                changingClientData[invoiceToExistingCustomerIndex].quotes = [];
              }
              changingClientData[invoiceToExistingCustomerIndex].quotes.push(
                quotetf
              );
            } else {
              // console.log("create new temp client");
              const newTempCustomer = {
                id: uuid(),
                input_by: `${userId}`,
                name: quotetf.client_name,
                email: quotetf.client_name,
                phone_number: quotetf.phone_number,
                company: "",
                designation: "",
                notes: [],
                invoices: [],
                quotes: [],
              };

              newTempCustomer.quotes.push(quotetf);
              // console.log(newTempCustomer);
              changingClientData.push(newTempCustomer);
            }
            if (viewWithoutCompany) {
              const invoiceToExistingDesignerIndex =
                changingClientData.findIndex(
                  (client) => client.name === quotetf.designer
                );
              if (invoiceToExistingDesignerIndex !== -1) {
                // console.log("add into existing client");
                // Ensure invoices is initialized as an empty array
                if (
                  !changingClientData[invoiceToExistingDesignerIndex].quotes
                ) {
                  changingClientData[invoiceToExistingDesignerIndex].quotes =
                    [];
                }
                changingClientData[invoiceToExistingDesignerIndex].quotes.push(
                  quotetf
                );
              } else {
                // console.log("create new temp client");
                const newTempCustomer = {
                  id: uuid(),
                  input_by: `${userId}`,
                  name: quotetf.designer,
                  email: "Designer",
                  phone_number: "Designer",
                  company: "",
                  designation: "",
                  notes: [],
                  invoices: [],
                  quotes: [],
                };

                newTempCustomer.quotes.push(quotetf);
                // console.log(newTempCustomer);
                changingClientData.push(newTempCustomer);
              }
            }
          }
        }
      }

      if (gottenInvoice) {
        let updatedInvoices = gottenInvoice.invoice.map((invoice) => ({
          ...invoice,
          less_cv: parseFloat(invoice.less_cv),
          discount: parseFloat(invoice.discount),
          status: parseFloat(invoice.status),
        }));
        updatedInvoices = filterDataByDateRange(updatedInvoices, dateRange);
        console.log(updatedInvoices);

        setAllInvoice(updatedInvoices);

        for (const invoicestf of updatedInvoices) {
          if (userData && userData.role === "Sales") {
            if (parseFloat(invoicestf.input_by) === userId) {
              const invoiceToExistingCustomerIndex =
                changingClientData.findIndex(
                  (client) => client.name === invoicestf.client_name
                );
              // console.log(invoiceToExistingCustomerIndex);

              if (invoiceToExistingCustomerIndex !== -1) {
                // console.log("add into existing client");
                // Ensure invoices is initialized as an empty array
                if (
                  !changingClientData[invoiceToExistingCustomerIndex].invoices
                ) {
                  changingClientData[invoiceToExistingCustomerIndex].invoices =
                    [];
                }
                changingClientData[
                  invoiceToExistingCustomerIndex
                ].invoices.push(invoicestf);
              } else {
                // console.log("create new temp client");
                const newTempCustomer = {
                  id: uuid(),
                  input_by: `${userId}`,
                  name: invoicestf.client_name,
                  email: invoicestf.client_name,
                  phone_number: invoicestf.phone_number,
                  company: "",
                  designation: "",
                  notes: [],
                  invoices: [],
                  quotes: [],
                };

                newTempCustomer.invoices.push(invoicestf);
                // console.log(newTempCustomer);
                changingClientData.push(newTempCustomer);
              }
              if (viewWithoutCompany) {
                const invoiceToExistingDesignerIndex =
                  changingClientData.findIndex(
                    (client) => client.name === invoicestf.designer
                  );
                if (invoiceToExistingDesignerIndex !== -1) {
                  // console.log("add into existing client");
                  // Ensure invoices is initialized as an empty array
                  if (
                    !changingClientData[invoiceToExistingDesignerIndex].invoices
                  ) {
                    changingClientData[
                      invoiceToExistingDesignerIndex
                    ].invoices = [];
                  }
                  changingClientData[
                    invoiceToExistingDesignerIndex
                  ].invoices.push(invoicestf);
                } else {
                  // console.log("create new temp client");
                  const newTempCustomer = {
                    id: uuid(),
                    input_by: `${userId}`,
                    name: invoicestf.designer,
                    email: "Designer",
                    phone_number: "Designer",
                    company: "",
                    designation: "",
                    notes: [],
                    invoices: [],
                    quotes: [],
                  };

                  newTempCustomer.invoices.push(invoicestf);
                  // console.log(newTempCustomer);
                  changingClientData.push(newTempCustomer);
                }
              }
            }
          } else {
            const invoiceToExistingCustomerIndex = changingClientData.findIndex(
              (client) => client.name === invoicestf.client_name
            );
            // console.log(invoiceToExistingCustomerIndex);

            if (invoiceToExistingCustomerIndex !== -1) {
              // console.log("add into existing client");
              // Ensure invoices is initialized as an empty array
              if (
                !changingClientData[invoiceToExistingCustomerIndex].invoices
              ) {
                changingClientData[invoiceToExistingCustomerIndex].invoices =
                  [];
              }
              changingClientData[invoiceToExistingCustomerIndex].invoices.push(
                invoicestf
              );
            } else {
              // console.log("create new temp client");
              const newTempCustomer = {
                id: uuid(),
                input_by: `${userId}`,
                name: invoicestf.client_name,
                email: invoicestf.client_name,
                phone_number: invoicestf.phone_number,
                company: "",
                designation: "",
                notes: [],
                invoices: [],
                quotes: [],
              };

              newTempCustomer.invoices.push(invoicestf);
              // console.log(newTempCustomer);
              changingClientData.push(newTempCustomer);
            }
            if (viewWithoutCompany) {
              console.log("false view");
              const invoiceToExistingDesignerIndex =
                changingClientData.findIndex(
                  (client) => client.name === invoicestf.designer
                );
              if (invoiceToExistingDesignerIndex !== -1) {
                // console.log("add into existing client");
                // Ensure invoices is initialized as an empty array
                if (
                  !changingClientData[invoiceToExistingDesignerIndex].invoices
                ) {
                  changingClientData[invoiceToExistingDesignerIndex].invoices =
                    [];
                }
                changingClientData[
                  invoiceToExistingDesignerIndex
                ].invoices.push(invoicestf);
              } else {
                // console.log("create new temp client");
                const newTempCustomer = {
                  id: uuid(),
                  input_by: `${userId}`,
                  name: invoicestf.designer,
                  email: "Designer",
                  phone_number: "Designer",
                  company: "",
                  designation: "",
                  notes: [],
                  invoices: [],
                  quotes: [],
                };

                newTempCustomer.invoices.push(invoicestf);
                // console.log(newTempCustomer);
                changingClientData.push(newTempCustomer);
              }
            }
          }
        }
        console.log(changingClientData);
        setClientData(changingClientData);
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchInvoice();
  }, [dateRange, viewWithoutCompany]);

  const handleAddNewCustomer = async (e) => {
    setSignUpError(false);
    e.preventDefault();

    const now = new Date();

    console.log(
      JSON.stringify({
        ...newCustomerData,
      })
    );
    try {
      const response = await fetch(
        "https://www.salestool.oasistechnologies.asia/laravel/api/createandupdatecustomer",
        {
          body: JSON.stringify({
            ...newCustomerData,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
        }
      );
      if (!response.ok) {
        setSignUpError(true);
        throw new Error("Network response was not ok");
      }

      // Clear the form data after adding the product
      setClientData([
        ...clientData,
        {
          ...newCustomerData,
          notes: [],
          invoices: [],
          quotes: [],
          created_at: now.toISOString(), // Format: "2023-08-15T04:25:58.000Z"
          updated_at: now.toISOString(),
        },
      ]);

      const fetchCustomers = async () => {
        const gottonCustomer = await getCustomer();
        if (gottonCustomer) {
          setClientData(gottonCustomer.customers);
        } else {
          console.log("Failed to fetch web sections.");
        }
      };

      fetchCustomers();

      const fetchCustomersForPopulating = async () => {
        const gottonCustomer = await getCustomer();
        if (gottonCustomer) {
          return gottonCustomer.customers;
        } else {
          console.log("Failed to fetch web sections.");
        }
      };

      const fetchInvoice = async () => {
        const changingClientData = await fetchCustomersForPopulating();
        // console.log(changingClientData);
        const gottenInvoice = await getInvoice();

        const gottenQuote = await getQuote();
        if (gottenQuote) {
          const updatedQuotes = gottenQuote.quotes.map((quote) => ({
            ...quote,
            less_cv: parseFloat(quote.less_cv),
            discount: parseFloat(quote.discount),
            status: parseFloat(quote.status),
          }));
          setAllQuote(updatedQuotes);
          for (const quotetf of updatedQuotes) {
            if (parseFloat(quotetf.input_by) === userId) {
              const invoiceToExistingCustomerIndex =
                changingClientData.findIndex(
                  (client) => client.name === quotetf.client_name
                );
              // console.log(invoiceToExistingCustomerIndex);

              if (invoiceToExistingCustomerIndex !== -1) {
                // console.log("add into existing client");
                // Ensure invoices is initialized as an empty array
                if (
                  !changingClientData[invoiceToExistingCustomerIndex].quotes
                ) {
                  changingClientData[invoiceToExistingCustomerIndex].quotes =
                    [];
                }
                changingClientData[invoiceToExistingCustomerIndex].quotes.push(
                  quotetf
                );
              } else {
                // console.log("create new temp client");
                const newTempCustomer = {
                  id: uuid(),
                  input_by: `${userId}`,
                  name: quotetf.client_name,
                  email: quotetf.client_name,
                  phone_number: quotetf.phone_number,
                  company: "",
                  designation: "",
                  notes: [],
                  invoices: [],
                  quotes: [],
                };

                newTempCustomer.quotes.push(quotetf);
                // console.log(newTempCustomer);
                changingClientData.push(newTempCustomer);
              }
            }
          }
        }

        if (gottenInvoice) {
          const updatedInvoices = gottenInvoice.invoice.map((invoice) => ({
            ...invoice,
            less_cv: parseFloat(invoice.less_cv),
            discount: parseFloat(invoice.discount),
            status: parseFloat(invoice.status),
          }));

          setAllInvoice(updatedInvoices);

          for (const invoicestf of updatedInvoices) {
            if (parseFloat(invoicestf.input_by) === userId) {
              const invoiceToExistingCustomerIndex =
                changingClientData.findIndex(
                  (client) => client.name === invoicestf.client_name
                );
              // console.log(invoiceToExistingCustomerIndex);

              if (invoiceToExistingCustomerIndex !== -1) {
                // console.log("add into existing client");
                // Ensure invoices is initialized as an empty array
                if (
                  !changingClientData[invoiceToExistingCustomerIndex].invoices
                ) {
                  changingClientData[invoiceToExistingCustomerIndex].invoices =
                    [];
                }
                changingClientData[
                  invoiceToExistingCustomerIndex
                ].invoices.push(invoicestf);
              } else {
                // console.log("create new temp client");
                const newTempCustomer = {
                  id: uuid(),
                  input_by: `${userId}`,
                  name: invoicestf.client_name,
                  email: invoicestf.client_name,
                  phone_number: invoicestf.phone_number,
                  company: "",
                  designation: "",
                  notes: [],
                  invoices: [],
                  quotes: [],
                };

                newTempCustomer.invoices.push(invoicestf);
                // console.log(newTempCustomer);
                changingClientData.push(newTempCustomer);
              }
            }
          }
          console.log(changingClientData);
          setClientData(changingClientData);
          const clientIndex = changingClientData.findIndex(
            (client) => client.name === newCustomerData.name
          );
          console.log(clientIndex);
          setSelectedClient(changingClientData[clientIndex]);

          setClientSelected(true);
        } else {
          console.log("Failed to fetch web sections.");
        }
      };

      fetchInvoice();

      setModalIsOpen(false);

      setNewCustomerData({
        id: "",
        name: "",
        email: "",
        username: "",
        password: "",
        created_at: "",
        updated_at: "",
      });
    } catch (error) {
      setSignUpError(true);
      console.error("Error signing up:", error);
    }
  };

  const [dateRangeIsOpen, setDateRangeIsOpen] = useState(false);

  const [reportIsOpen, setReportIsOpen] = useState(false);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    // Add event listener to update the screenWidth state when the window is resized
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getTotalRevenue = () => {
    let letOutstanding = 0;
    if (clientSelected) {
      if (selectedClient.invoices !== null) {
        for (const invoice of selectedClient.invoices) {
          // console.log(1);
          letOutstanding +=
            parseFloat(
              invoice.product.reduce(
                (acc, item) =>
                  acc +
                  (item.quantity ? parseFloat(item.quantity) : 0) *
                    (item.UnitPrice ? parseFloat(item.UnitPrice) : 0),
                0
              ) -
                (invoice.discount ? parseFloat(invoice.discount) : 0) -
                (invoice.less_cv ? parseFloat(invoice.less_cv) : 0)
            ) * 1.09;
        }
      }
    } else {
      for (const company of filteredCompany) {
        if (company.invoices !== null) {
          for (const invoice of company.invoices) {
            // console.log(1);
            letOutstanding +=
              parseFloat(
                invoice.product.reduce(
                  (acc, item) =>
                    acc +
                    (item.quantity ? parseFloat(item.quantity) : 0) *
                      (item.UnitPrice ? parseFloat(item.UnitPrice) : 0),
                  0
                ) -
                  (invoice.discount ? parseFloat(invoice.discount) : 0) -
                  (invoice.less_cv ? parseFloat(invoice.less_cv) : 0)
              ) * 1.09;
          }
        }
      }
    }
    return parseFloat(letOutstanding.toFixed(2));
  };

  const getOutstandingValue = () => {
    let letOutstanding = 0;

    if (clientSelected) {
      if (selectedClient.invoices !== null) {
        for (const invoice of selectedClient.invoices) {
          letOutstanding +=
            parseFloat(
              invoice.product.reduce(
                (acc, item) =>
                  acc +
                  (item.quantity ? parseFloat(item.quantity) : 0) *
                    (item.UnitPrice ? parseFloat(item.UnitPrice) : 0),
                0
              ) -
                (invoice.discount ? parseFloat(invoice.discount) : 0) -
                (invoice.less_cv ? parseFloat(invoice.less_cv) : 0)
            ) *
              1.09 -
            (invoice.record_payment ? parseFloat(invoice.record_payment) : 0);
        }
      }
    } else {
      for (const company of filteredCompany) {
        if (company.invoices !== null) {
          for (const invoice of company.invoices) {
            letOutstanding +=
              parseFloat(
                invoice.product.reduce(
                  (acc, item) =>
                    acc +
                    (item.quantity ? parseFloat(item.quantity) : 0) *
                      (item.UnitPrice ? parseFloat(item.UnitPrice) : 0),
                  0
                ) -
                  (invoice.discount ? parseFloat(invoice.discount) : 0) -
                  (invoice.less_cv ? parseFloat(invoice.less_cv) : 0)
              ) *
                1.09 -
              (invoice.record_payment ? parseFloat(invoice.record_payment) : 0);
          }
        }
      }
    }
    return parseFloat(letOutstanding.toFixed(2));
  };

  const getReceiptValue = () => {
    let letReceipts = 0;
    if (clientSelected) {
      if (selectedClient.invoices !== null) {
        for (const invoice of selectedClient.invoices) {
          letReceipts += parseFloat(invoice.record_payment);
        }
      }
    } else {
      for (const company of filteredCompany) {
        if (company.invoices !== null) {
          for (const invoice of company.invoices) {
            letReceipts += parseFloat(invoice.record_payment);
          }
        }
      }
    }
    return letReceipts.toFixed(2);
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      responsive: true,
    },
    type: "bar",
    scales: {
      xAxes: [
        {
          stacked: true,
        },
      ],
      yAxes: [
        {
          stacked: true,
        },
      ],
    },
  };

  const data2 = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Expected Revenue",
        data: [52, 91, 33, 85, 52, 23, 73, 57, 35, 82],
        backgroundColor: highlightColor,
        borderColor: highlightColor,
        borderWidth: 1,
        borderRadius: 8,
      },
      {
        label: "Receipt",
        data: [35, 82, 83, 25, 52, 91, 33, 85, 52, 23],
        backgroundColor: "rgb(40, 40, 40)",
        borderColor: "rgb(40, 40, 40)",
        borderWidth: 1,
        borderRadius: 8,
      },
    ],
  };
  const monthlyTotals = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };

  // Loop through filtered invoice data and calculate monthly totals
  const monthAbbreviations = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  // Loop through filtered invoice data and calculate monthly totals
  if (clientSelected) {
    if (selectedClient.invoices) {
      selectedClient.invoices.forEach((invoice) => {
        const month = monthAbbreviations[invoice.date.split("-")[1]];
        const total = invoice.product.reduce(
          (acc, item) =>
            acc +
            (item.quantity ? parseFloat(item.quantity) : 0) *
              (item.UnitPrice ? parseFloat(item.UnitPrice) : 0),
          0
        );
        // console.log(monthlyTotals[month]);
        monthlyTotals[month] += parseFloat(
          (
            (total -
              parseFloat(invoice.discount) -
              parseFloat(invoice.less_cv)) *
            1.09
          ).toFixed(2)
        );
      });
    }
  } else {
    filteredCompany
      .filter((client) => viewWithoutCompany || client.company === "")
      .forEach((company) => {
        if (company.invoices) {
          // Check if invoices is not null
          company.invoices.forEach((invoice) => {
            const month = monthAbbreviations[invoice.date.split("-")[1]];
            const total = invoice.product.reduce(
              (acc, item) =>
                acc +
                (item.quantity ? parseFloat(item.quantity) : 0) *
                  (item.UnitPrice ? parseFloat(item.UnitPrice) : 0),
              0
            );
            // console.log(monthlyTotals[month]);
            monthlyTotals[month] += parseFloat(
              (
                (total -
                  parseFloat(invoice.discount) -
                  parseFloat(invoice.less_cv)) *
                1.09
              ).toFixed(2)
            );
          });
        }
      });
  }

  // Fill the data array in the datasets
  data2.datasets[0].data = Object.values(monthlyTotals);

  const monthlyPayments = {
    Jan: 0,
    Feb: 0,
    Mar: 0,
    Apr: 0,
    May: 0,
    Jun: 0,
    Jul: 0,
    Aug: 0,
    Sep: 0,
    Oct: 0,
    Nov: 0,
    Dec: 0,
  };

  // Loop through filtered invoice data and accumulate record_payment values for each month
  if (clientSelected) {
    if (selectedClient.invoices) {
      // console.log(selectedClient);
      selectedClient.invoices.forEach((invoice) => {
        const month = monthAbbreviations[invoice.date.split("-")[1]];
        const recordPayment = parseFloat(invoice.record_payment);
        monthlyPayments[month] += recordPayment;
      });
    }
  } else {
    filteredCompany
      .filter((client) => viewWithoutCompany || client.company === "")
      .forEach((company) => {
        if (company.invoices) {
          // console.log(company);
          company.invoices.forEach((invoice) => {
            const month = monthAbbreviations[invoice.date.split("-")[1]];
            const recordPayment = parseFloat(invoice.record_payment);
            monthlyPayments[month] += recordPayment;
          });
        }
      });
  }

  // Create an array of cumulative payments
  const cumulativePayments = Object.values(monthlyPayments);

  // Assign the cumulative payments to the second dataset in data2
  data2.datasets[1].data = cumulativePayments;
  // console.log(data2);
  const screenWidthForChart = screenWidth / 1.4;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Modal
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          setNewCustomerData({
            id: "",
            name: "",
            email: "",
            username: "",
            password: "",
            created_at: "",
            updated_at: "",
          });
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            height: "700px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflowX: "hidden",
            overflowY: "hidden",
            padding: "none",
          },
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "calc(100% - 40px)",
              justifyContent: "flex-end",
              padding: "20px",
            }}
          >
            <div
              className="close_icon"
              style={{
                opacity: "1",
                transition: "all 0.3s ease-in-out",
                border: "1px solid lightgrey",
                borderRadius: "50%",
                height: "40px",
                width: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                setModalIsOpen(false);
                setNewCustomerData({
                  id: "",
                  name: "",
                  email: "",
                  username: "",
                  password: "",
                  created_at: "",
                  updated_at: "",
                });
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              width: "calc(100% - 40px)",
              padding: "0px 20px",
              height: "75%",
            }}
          >
            <form onSubmit={handleAddNewCustomer} style={{ width: "100%" }}>
              <AccountCircleIcon
                style={{ color: "lightgrey", fontSize: "200px" }}
              />
              <div style={{ width: "100%" }}>
                <input
                  disabled={clientSelected}
                  type="text"
                  name="name"
                  value={newCustomerData.name}
                  onChange={handleNewCustomerInput}
                  placeholder="Customer name"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "50%",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  // disabled={clientSelected}
                  type="text"
                  name="email"
                  value={newCustomerData.email}
                  onChange={handleNewCustomerInput}
                  placeholder="Email address"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "50%",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="phone_number"
                  value={newCustomerData.phone_number}
                  onChange={handleNewCustomerInput}
                  placeholder="Phone number"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "50%",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="company"
                  value={newCustomerData.company}
                  onChange={handleNewCustomerInput}
                  placeholder="Company name"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "50%",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <input
                  type="text"
                  name="designation"
                  value={newCustomerData.designation}
                  onChange={handleNewCustomerInput}
                  placeholder="Designation"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "50%",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                  }}
                />
              </div>
              <div style={{ color: "red", textAlign: "center" }}>
                {signUpError ? "invalid email" : null}
              </div>
              <button
                type="submit"
                style={{
                  background: highlightColor,
                  padding: "8px 25px",
                  color: "white",
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: "none",
                  outline: "none",
                  fontSize: "18px",
                  marginTop: "40px",
                }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <Modal
        ariaHideApp={false}
        isOpen={notesModal}
        onRequestClose={() => {
          setNotesModal(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: "500px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflowX: "hidden",
            overflowY: "hidden",
            padding: "none",
          },
        }}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <div
            style={{
              display: "flex",
              width: "calc(100% - 40px)",
              justifyContent: "flex-end",
              padding: "20px",
            }}
          >
            <div
              className="close_icon"
              style={{
                opacity: "1",
                transition: "all 0.3s ease-in-out",
                border: "1px solid lightgrey",
                borderRadius: "50%",
                height: "40px",
                width: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => setNotesModal(false)}
            >
              <CloseIcon />
            </div>
          </div>
          <div
            style={{
              textAlign: "center",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              width: "calc(100% - 40px)",
              padding: "0px 20px",
              height: "75%",
            }}
          >
            <form onSubmit={handlePushNotes} style={{ width: "100%" }}>
              <div style={{ width: "100%" }}>
                <textarea
                  type="text"
                  value={typingNotes}
                  onChange={(e) => setTypingNotes(e.target.value)}
                  placeholder="Type here"
                  style={{
                    textAlign: "center",
                    padding: "8px 20px",
                    marginTop: "15px",
                    width: "80%",
                    borderRadius: "12px",
                    outline: "none",
                    border: "1px solid lightgrey",
                    height: "200px",
                  }}
                />
              </div>
              <div style={{ color: "red", textAlign: "center" }}>
                {signUpError ? "invalid email or password" : null}
              </div>
              <button
                type="submit"
                style={{
                  background: highlightColor,
                  padding: "8px 25px",
                  color: "white",
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: "none",
                  outline: "none",
                  fontSize: "18px",
                  marginTop: "40px",
                }}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </Modal>

      <div
        style={{
          padding: "0px 20px",
          height: "70px",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid lightgrey",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              margin: "0px",
              fontSize: "32PX",
              fontWeight: "700",
              marginRight: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              setClientSelected(false);
              setReportIsOpen(false);
            }}
          >
            Customer
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                background: highlightColor,
                borderRadius: "7px",
                display: "flex",
                alignItems: "center",
                padding: "6px 15px 6px 10px",
                cursor: "pointer",
                marginRight: "5px",
                marginTop: "5px",
              }}
              onClick={() => {
                console.log(selectedClient);
                if (clientSelected) {
                  setNewCustomerData({
                    id: selectedClient.id || "",
                    name: selectedClient.name,
                    email: selectedClient.email,
                    phone_number: selectedClient.phone_number,
                    company: selectedClient.company,
                    designation: selectedClient.designation,
                  });
                }
                setModalIsOpen(true);
              }}
            >
              <AddIcon style={{ color: "white", fontSize: "18px" }} />
              <div
                style={{
                  color: "white",
                  fontSize: "13px",
                  marginBottom: "2.5px",
                }}
              >
                {clientSelected ? "Update Profile" : "Create Profile"}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "flex-end",
            flexDirection: "row",
            fontSize: "13px",
            gap: 10,
          }}
        >
          <div
            style={{
              background: "white",
              padding: "8px 20px",
              borderRadius: "7px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              cursor: "pointer",
            }}
            onClick={() => setReportIsOpen(!reportIsOpen)}
          >
            Report
          </div>
          <div
            style={{
              background: "white",
              padding: "8px 20px",
              borderRadius: "7px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              cursor: "pointer",
            }}
            onClick={() => setDateRangeIsOpen(!dateRangeIsOpen)}
          >
            Filter by date
          </div>
          {dateRangeIsOpen ? (
            <div
              style={{
                position: "absolute",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                zIndex: 20,
                top: 40,
                right: 0,
              }}
            >
              <DateRangePicker
                ranges={[dateRange]}
                onChange={(range) => setDateRange(range.selection)}
                rangeColors={highlightColor}
                color={highlightColor}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {!reportIsOpen ? (
        clientSelected ? null : (
          <div
            style={{
              // marginTop:"2vh",
              width: "calc(100% - 40px)",
              padding: "2vh 20px 0px 20px",
              height: "5vh",
              // background: "red",
              background: "rgb(245, 242, 240)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <input
              className="search_input"
              placeholder="Search customer name"
              type="text"
              value={searchQueryClient}
              onChange={handleSearchClientInputChange}
              style={{
                padding: "10px 12px",
                width: "calc(50% - 24px)",
                border: "none",
                background: "white",
                borderRadius: "12px",
                marginRight: "5px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              }}
            />
            <div
              style={{
                width: "calc(50% - 24px)",
                position: "relative",
              }}
            >
              <input
                type="checkbox"
                style={{
                  accentColor: highlightColor,
                  width: "20px",
                  height: "20px",
                  position: "absolute",
                  left: 6,
                  top: "43%",
                  transform: "translate(0, -50%)",
                  border: "1px solid lightgrey",
                }}
                checked={viewWithoutCompany} // Set the checked attribute based on the state value
                onChange={(e) => {
                  setViewWithoutCompany(e.target.checked);
                  if (!e.target.checked) {
                    setSearchQueryCompany("");
                  }
                }}
              />
              <input
                className="search_input"
                placeholder="Search company name"
                type="text"
                disabled={!viewWithoutCompany}
                value={searchQueryCompany}
                onChange={handleSearchCompanyInputChange}
                style={{
                  padding: "10px 12px 10px 40px",
                  border: "none",
                  background: "white",
                  borderRadius: "12px",
                  marginRight: "5px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  width: "calc(100% - 60px)",
                }}
              />
            </div>
          </div>
        )
      ) : null}
      <div
        style={{
          width: "calc(100% - 40px)",
          padding: "20px",
          background: "rgb(245, 242, 240)",
          height: clientSelected ? "89vh" : "80vh",
        }}
      >
        {!reportIsOpen ? (
          clientSelected ? (
            <ClientProfile clientData={selectedClient} />
          ) : (
            <div
              style={{
                width: "100%",
                background: "white",
                height: "100%",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                borderRadius: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  padding: "15px 20px",
                  fontSize: "15px",
                  fontWeight: "700",
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <div style={{ width: "20%" }}>Name</div>
                <div style={{ width: "30%" }}>Email</div>
                <div style={{ width: "15%" }}>Contact</div>
                <div style={{ width: "20%" }}>Company</div>
                <div style={{ width: "15%" }}>Designation</div>
              </div>
              <div style={{ overflowY: "scroll", height: "75vh" }}>
                {filteredCompany
                  .filter(
                    (client) => viewWithoutCompany || client.company === ""
                  )
                  .map((client, index) => (
                    <div
                      className="invoice_item"
                      key={index}
                      style={{
                        display: "flex",
                        padding: "10px 20px",
                        fontSize: "13px",
                        fontWeight: "500",
                        borderBottom: "1px solid rgba(0,0,0,0.05)",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        console.log(client);
                        setSelectedClient(client);
                        setClientSelected(true);
                      }}
                    >
                      <div style={{ width: "20%" }}>{client.name}</div>
                      <div style={{ width: "30%" }}>{client.email}</div>
                      <div style={{ width: "15%" }}>{client.phone_number}</div>
                      <div style={{ width: "20%" }}>{client.company}</div>
                      <div style={{ width: "15%" }}>{client.designation}</div>
                    </div>
                  ))}
              </div>
            </div>
          )
        ) : (
          <div
            style={{
              width: "100%",
              height: "85vh",
            }}
          >
            <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <div
                style={{
                  position: "relative",
                  width:
                    windowHeight > screenWidth
                      ? "calc(50% - 60px)"
                      : "calc(25% - 60px)",
                  background: "#282828",
                  marginRight: "20px",
                  height: windowHeight > screenWidth ? "120px" : "80px",
                  borderRadius: "15px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div
                  className="blink"
                  style={{
                    position: "absolute",
                    top: 15,
                    right: 15,
                    background: highlightColor,
                    width: "8px",
                    height: "8px",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 10px #eba1a2",
                  }}
                ></div>
                <div
                  style={{
                    width: "90%",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: highlightColor,
                  }}
                >
                  Forecast Total Revenue
                </div>
                <div
                  style={{
                    width: "90%",
                    fontSize: "30px",
                    fontWeight: "700",
                    color: highlightColor,
                  }}
                >
                  SGD {getTotalRevenue()}
                </div>
              </div>
              <div
                style={{
                  width:
                    windowHeight > screenWidth
                      ? "calc(50% - 60px)"
                      : "calc(25% - 60px)",
                  background: "white",
                  marginRight: "20px",
                  height: windowHeight > screenWidth ? "120px" : "80px",
                  borderRadius: "15px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div
                  style={{
                    width: "90%",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#282828",
                  }}
                >
                  Outstanding
                </div>
                <div
                  style={{
                    width: "90%",
                    fontSize: "30px",
                    fontWeight: "700",
                    color: "#282828",
                  }}
                >
                  SGD {getOutstandingValue()}
                </div>
              </div>
              <div
                style={{
                  width:
                    windowHeight > screenWidth
                      ? "calc(50% - 60px)"
                      : "calc(25% - 60px)",
                  background: "white",
                  marginRight: "20px",
                  height: windowHeight > screenWidth ? "120px" : "80px",
                  borderRadius: "15px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div
                  style={{
                    width: "90%",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#282828",
                  }}
                >
                  Receipts
                </div>
                <div
                  style={{
                    width: "90%",
                    fontSize: "30px",
                    fontWeight: "700",
                    color: "#282828",
                  }}
                >
                  SGD {getReceiptValue()}
                </div>
              </div>
              <div
                style={{
                  width:
                    windowHeight > screenWidth
                      ? "calc(50% - 60px)"
                      : "calc(25% - 60px)",
                  background: "white",
                  marginRight: "20px",
                  height: windowHeight > screenWidth ? "120px" : "80px",
                  borderRadius: "15px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <div
                  style={{
                    width: "90%",
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#282828",
                  }}
                >
                  Expense
                </div>
                <div
                  style={{
                    width: "90%",
                    fontSize: "30px",
                    fontWeight: "700",
                    color: "#282828",
                  }}
                >
                  SGD 0.00
                </div>
              </div>
            </div>
            <div
              style={{
                width: "60%",
                background: "white",
                marginRight: "20px",
                height: "300px",
                borderRadius: "15px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  padding: "20px 0px 20px 0px",
                  width: "90%",
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#282828",
                }}
              >
                Sales and Receipts
              </div>
              <div style={{ height: "260px", padding: "0px 20px 20px 20px" }}>
                <Bar
                  options={options2}
                  data={data2}
                  width={screenWidthForChart}
                  height={400}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Customer;
